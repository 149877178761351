import { HttpClientService } from "../httpclient.service";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClientModule } from "@angular/common/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Exceldata } from "./marketing/smsmarketing/models/ExecelData.model";
import { FormBuilder, RequiredValidator, Validators } from "@angular/forms";
import { Exceldata2 } from "./marketing/smsmarketing/models/ExecelData.model2";
import { BehaviorSubject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { UhleadsComponent } from "./uhleads/uhleads.component";
export enum REGEX {
  EMAIL = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,4}',
  PHONE = '[0-9]{10}$',
  PASSWORD = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
  ZIP = '^[a-zA-Z0-9,\'-]*$',
  NUMBER = '^[0-9]*$',
  URL = '^(http:\/\/www\.|https:\/\/www\.|www\.|http:\/\/|https:\/\/)[a-zA-Z0-9\.\-]+\\.[a-zA-Z]{2,5}[\.]{0,1}',
  CHARACTER = "^[a-zA-Z /'.-]*$",
  SPECIAL_PHONE = '^[+|0-9]+[0-9]{1,}$',
  ADDRESS = '^[a-zA-Z0-9\s,\'-]*$',
}
@Injectable({
  providedIn: "root",
})
export class UhLeadsService {
  baseApiUrl = environment.baseApiUrl2;
  baseApiUrl2 = environment.SMSapiurl;
  userData = JSON.parse(`${localStorage.getItem("userData")}`);
  twilioApiUrl: any = environment.twilioApiUrl;
  ActivityData: any;

  startCall = new BehaviorSubject<any>(null);
  stopCall = new BehaviorSubject<any>(null);
  conferenceCall = new BehaviorSubject<any>(null);
  startTimer = new BehaviorSubject<any>(null);
  getCallSid = new BehaviorSubject<any>(null);
  viewReceivedLead = new BehaviorSubject<any>(null);
  unreadCountRec = new BehaviorSubject<any>(null);
  pageNumber = new BehaviorSubject<any>(null);
  totalPages = new BehaviorSubject<any>(null);
  incomingSms = new BehaviorSubject<any>(null);
  callStatus = new BehaviorSubject<any>(null);

  // SFC
  startCallsfc = new BehaviorSubject<any>(null);
  stopCallsfc = new BehaviorSubject<any>(null);
  conferenceCallsfc = new BehaviorSubject<any>(null);
  startTimersfc = new BehaviorSubject<any>(null);
  getCallSidsfc = new BehaviorSubject<any>(null);
  viewReceivedLeadsfc = new BehaviorSubject<any>(null);
  pageNumbersfc = new BehaviorSubject<any>(null);
  totalPagessfc = new BehaviorSubject<any>(null);
  incomingSmssfc = new BehaviorSubject<any>(null);
  callStatussfc = new BehaviorSubject<any>(null);

  // UWLA
  startCalluwla = new BehaviorSubject<any>(null);
  stopCalluwla = new BehaviorSubject<any>(null);
  conferenceCalluwla = new BehaviorSubject<any>(null);
  startTimeruwla = new BehaviorSubject<any>(null);
  getCallSiduwla = new BehaviorSubject<any>(null);
  viewReceivedLeaduwla = new BehaviorSubject<any>(null);
  pageNumberuwla = new BehaviorSubject<any>(null);
  totalPagesuwla = new BehaviorSubject<any>(null);
  incomingSmsuwla = new BehaviorSubject<any>(null);
  callStatusuwla = new BehaviorSubject<any>(null);
  // judson
  startCalljudson = new BehaviorSubject<any>(null);
  stopCalljudson = new BehaviorSubject<any>(null);
  conferenceCalljudson = new BehaviorSubject<any>(null);
  startTimerjudson = new BehaviorSubject<any>(null);
  getCallSidjudson = new BehaviorSubject<any>(null);
  viewReceivedLeadjudson = new BehaviorSubject<any>(null);
  pageNumberjudson = new BehaviorSubject<any>(null);
  totalPagesjudson = new BehaviorSubject<any>(null);
  incomingSmsjudson = new BehaviorSubject<any>(null);
  callStatusjudson = new BehaviorSubject<any>(null);
  editLead = this.formBuilder.group({
    FirstName: ["", [Validators.required, Validators.pattern(REGEX.CHARACTER), Validators.minLength(2), Validators.maxLength(50)]],
    LastName: ["", [Validators.required, Validators.pattern(REGEX.CHARACTER), Validators.minLength(2), Validators.maxLength(50)]],
    CountryCode: ["", [Validators.required, Validators.pattern(REGEX.NUMBER)]],
    PhoneNumber: ["", [Validators.required, Validators.pattern(REGEX.NUMBER), Validators.minLength(7), Validators.maxLength(15)]],
    Email: ["", [Validators.required, Validators.pattern(REGEX.EMAIL)]],
    DOB: [""],
    EnrollmentYear: ["", [Validators.required, Validators.pattern(REGEX.NUMBER)]],
    EnrollmentTerm: ["", [Validators.required]],
    Address: [""],
    City: [""],
    State: [""],
    Zip: [""],
    Country: [""],
    LeadComment: [""],
    CallStatus: [""],
    studentapplicationtype: [""]
  });

  createLead = this.formBuilder.group({
    firstName: ["", [Validators.required, Validators.pattern(REGEX.CHARACTER), Validators.minLength(2), Validators.maxLength(50)]],
    lastName: ["", [Validators.required, Validators.pattern(REGEX.CHARACTER), Validators.minLength(2), Validators.maxLength(50)]],
    countryCode: ["", [Validators.required, Validators.pattern(REGEX.NUMBER)]],
    form_name: [""],
    phoneNumber: ["", [Validators.required, Validators.pattern(REGEX.NUMBER), Validators.minLength(7), Validators.maxLength(15)]],
    email: ["", [Validators.required, Validators.pattern(REGEX.EMAIL)]],
    channel: [""],
    assignedTo: [this.userData ? this.userData["mail"] : ""],
    module: ["LeadsMarketing"],
    Miscellaneous: [""],
    program: ["", [Validators.required]],
    applicationType: 'N',
    studentapplicationtype: ["", [Validators.required]],
    EnrollmentTerm:[''],
    EnrollmentYear:[''],
  });

  constructor(
    private http: HttpClientService,
    private http2: HttpClient,
    private readonly formBuilder: FormBuilder,
    private toastr: ToastrService
  ) { }

  resetCreateLead() {
    this.createLead.reset();
    this.createLead.patchValue({
      assignedTo: this.userData["mail"],
      module: "LeadsMarketing",
      applicationType: 'N'
    });
  }

  get createLeadValue() {
    return this.createLead.value;
  }

  getActivityTable2(body) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/getDashboardLeads`,
      body
    );
  }

  getStudentActivity(id) {
    return this.http.get(`${this.baseApiUrl}campbellsville/getLead1/` + id);
  }

  getSms(id) {
    return this.http.get(`${this.baseApiUrl}communication/campbellsvilleSMS/` + id);
  }
  getSmsWp(data) {
    return this.http.post(`${this.baseApiUrl}communication/getWhatsAppMessages`, data);
  }

  sendSms(data, projectId) {
    return this.http.post(`${this.baseApiUrl2}` + projectId + `/messaging-adapter/sendSMS`, data);
  }

  sendWhatsapp(data, projectId) {
    return this.http.post(`${this.baseApiUrl2}` + projectId + `/messaging-adapter/sendWhatsapp`, data);
  }

  // getAgentList(){
  //   return this.http.get(`${this.baseApiUrl}campbellsville/getGBPAgents`);
  // }
  getAgentList(projectId, agentId) {
    // return this.http.get(
    //   `${this.baseApiUrl}contacts/` + projectId + "/" + agentId + "/agent"
    // );
    return this.http.get(
      `${this.baseApiUrl}CRMAgents/` + projectId
    );
  }

  getGBPAgentList() {
    return this.http.get(`${this.baseApiUrl}campbellsville/getGBPAgents`);
  }

  assignLeads(body) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/AssignCRMLead`,
      body
    );
  }

  bulkLeadsChange(body) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/updateMultipleStatus`,
      body
    );
  }

  getAudio(url) {
    return this.http.get(url);
  }

  postCallData(data: {
    data: Exceldata2[];
    createdBy: string;
    ProjectId: number;
    addType: string;
  }) {
    return this.http.post(`${this.baseApiUrl}campbellsville/addCallData`, data);
  }

  updateCallStatus(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/updateCallStatus`,
      data
    );
  }

  updateCallSid(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/updateCallSid`,
      data
    );
  }

  updateComments(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/updateCallComments`,
      data
    );
  }

  updateComments2(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/updateCallComments2`,
      data
    );
  }

  updateLead(data) {
    return this.http.post(`${this.baseApiUrl}campbellsville/updateLead`, data);
  }
  emailIdValidator(data) {
    return this.http.post(`${this.baseApiUrl}campbellsville/emailIdValidator`, data);
  }

  showSuccess(message, title) {
    this.toastr.success(message, title);
  }
  showError(msg: string, title: string) {
    this.toastr.error(msg, title);
  }
  showInfo(msg: string, title: string) {
    this.toastr.info(msg, title);
  }

  showWarning(message, title) {
    this.toastr.warning(message, title, {
      disableTimeOut: true,
    });
  }
  showLoading(msg: string, title: string) {
    this.toastr.info(msg, title, {
      disableTimeOut: true,
    });
  }

  uploadDocument(data, docId) {
    return this.http.post(`${this.baseApiUrl}UploadDocument`, data);
  }

  uploadCustomDocument(data) {
    return this.http.post(`${this.baseApiUrl}addCustomDocUpload`, data);
  }

  // deleteCustomDocument(data) {
  //   return this.http.get(
  //     `${this.baseApiUrl}deleteCustomDocName` +
  //       "/" +
  //       data.StudentdocId +
  //       "/" +
  //       data.docName
  //   );
  // }

  deleteCustomDocument(data) {
    return this.http.post(
      `${this.baseApiUrl}deleteCustomDocName`, data
    );
  }

  clear() {
    this.toastr.clear();
  }

  getDocumentDetails(GUID) {
    return this.http.get(
      `${this.baseApiUrl}GetListOfDocumentsStatus/` + GUID
    );
  }

  getDocumentComments(leadId) {
    return this.http.get(
      `${this.baseApiUrl}campbellsville/getLead1/` + leadId
    );
  }

  updateValidationStatus(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/documentValidation2`,
      data
    );
  }

  postDocumentComment(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/addComments`,
      data
    );
  }

  convert2dArraytoObject(formatingData): Exceldata2[] {
    let excelData: Exceldata2[] = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};

      if (formatingData[inner][0]) {
        convertData["GUID"] = formatingData[inner][0];
        convertData["firstName"] = formatingData[inner][1];
        convertData["lastName"] = formatingData[inner][2];
        convertData["countryCode"] = formatingData[inner][3] || '';
        convertData["phoneNumber"] = formatingData[inner][4];
        convertData["email"] = formatingData[inner][5];
        convertData["AssignedTo"] = formatingData[inner][6];
        // convertData["module"] = formatingData[inner][7];
        // convertData["channel"] = formatingData[inner][8];
        convertData["Program"] = formatingData[inner][7];
        // convertData["appStatus"] = formatingData[inner][10];

        // convertData["Miscellaneous"] = {
        //   form_id: "",
        //   form_name: formatingData[inner][8],
        //   "Choose Batch": "",
        //   "Training Mode": "",
        // };
        convertData["ApplicationType"] = formatingData[inner][8];
        convertData["CreatedDate"] = formatingData[inner][9];
        convertData["Comments"] = formatingData[inner][10];
        excelData.push(convertData);
      }
    }

    return excelData;
  }

  convert2dArraytoObjectSFC(formatingData): Exceldata2[] {
    let excelData: Exceldata2[] = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};

      if (formatingData[inner][0]) {
        convertData["GUID"] = formatingData[inner][0];
        convertData["firstName"] = formatingData[inner][1];
        convertData["lastName"] = formatingData[inner][2];
        convertData["countryCode"] = formatingData[inner][3] || '';
        convertData["phoneNumber"] = formatingData[inner][4];
        convertData["email"] = formatingData[inner][5];
        convertData["AssignedTo"] = formatingData[inner][6];
        convertData["Program"] = formatingData[inner][7];
        convertData["ApplicationType"] = formatingData[inner][8];
        convertData["CreatedDate"] = formatingData[inner][9];
        convertData["Comments"] = formatingData[inner][10];
        convertData["studentapplicationtype"] = formatingData[inner][11];
        convertData["EnrollmentTerm"] = formatingData[inner][12];
        convertData["EnrollmentYear"] = formatingData[inner][13];
        convertData["isManualLeadCRM"] = true;
        excelData.push(convertData);
      }
    }

    return excelData;
  }

  convert2dArraytoObjectTransfer(formatingData): Exceldata2[] {
    let excelData: Exceldata2[] = [];
    for (let inner = 1; inner <= formatingData.length - 1; inner++) {
      let convertData: any = {};

      if (formatingData[inner][0]) {
        convertData["salesforceId"] = formatingData[inner][0];
        convertData["firstName"] = formatingData[inner][1];
        convertData["lastName"] = formatingData[inner][2];
        convertData["phoneCountryCode"] = formatingData[inner][3] || '';
        convertData["phoneWithoutCode"] = formatingData[inner][4];
        convertData["emailId"] = formatingData[inner][5];
        convertData["Assignedto"] = formatingData[inner][6];
        convertData["ApplyingAs"] = formatingData[inner][7];
        convertData["ApplyingFor"] = formatingData[inner][8];
        convertData["CreatedDate"] = formatingData[inner][9];
        convertData["Comments"] = formatingData[inner][10];
        convertData["fromUS"] = formatingData[inner][11];
        convertData["password"] = formatingData[inner][12];
        excelData.push(convertData);
      }
    }

    return excelData;
  }

  uploadmultiple() {

  }
  getTopBarCRM(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/getTopBarCRM`,
      data
    );

  }

  GetSearchDashboardLeads(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/getSearchDashboardLeads`,
      data
    );
  }

  getCardLeadByType(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/getDashboardLeads2`,
      data
    );
  }
  // DASBOARD NEW API FOR REPORT LIKE FILTER
  getCardLeadByType1(data) {
    return this.http.post(
      `${this.baseApiUrl}campbellsville/getDashboardLeads3`,
      data
    );
  }
  getProjectList(id, token) {
    return this.http.post(`${this.baseApiUrl}reports/projectsList`, {
      userId: id,
      token: token
    })
  }
  getCourses(id, token) {
    return this.http.get(`${this.baseApiUrl}campbellsville/getListOfProgramsNew`)
  }

  getAgents(projectId) {
    var postData = {
      platform: 'CRM',
      projectId: projectId
    }
    return this.http.post(`${this.baseApiUrl}/campbellsville/getListOfAgentsNew`, postData)
  }

  getEnrollmentTerm(id, token) {

    return this.http.post(`${this.baseApiUrl}campbellsville/enrollmentTermListNew`, {
      userId: id,
      token: token,
      platform: "CRM"
    })
  }

  getEnrollmentYear(id, token) {
    return this.http.post(`${this.baseApiUrl}/campbellsville/enrollmentYearListNew`, {
      userId: id,
      token: token,
      platform: "CRM"
    })
  }

  getReports(obj) {
    return this.http.post(`${this.baseApiUrl}reports`, obj)
  }
  getReportsByApplicationType(obj, type) {
    return this.http.post(`${this.baseApiUrl}reports/${type}`, obj)
  }
  getApplication(obj) {
    return this.http.post(`${this.baseApiUrl}campbellsville/applicationslist`, obj)
  }
  getRealTimeEentNameReport(obj?: any) {
    return this.http.post(`${this.baseApiUrl}/campbellsville/getRealTimeEentNameReport`, obj)
  }
  getRealTimeCountryReport(obj?: any) {
    return this.http.post(`${this.baseApiUrl}/campbellsville/getRealTimeCountryReport`, obj)
  }
  getRealTimeCityReport(obj?: any) {
    return this.http.post(`${this.baseApiUrl}/campbellsville/getRealTimeCityReport`, obj)
  }
  getRealTimeAudienceNameReport(obj?: any) {
    return this.http.post(`${this.baseApiUrl}/campbellsville/getRealTimeAudienceNameReport`, obj)
  }
  getRunReports(obj?: any) {
    return this.http.post(`${this.baseApiUrl}/campbellsville/getRunReports`, obj)
  }
  getRealTimeCountryReportChart(obj?: any) {
    return this.http.post(`${this.baseApiUrl}/campbellsville/getRealTimeCountryReportChart`, obj)
  }
  getCountByCallStatus(data) {
    return this.http.post(`${this.baseApiUrl}/campbellsville/getCountByCallStatus`, data);
  }
  getCustomDocList() {
    return this.http.get(`${this.baseApiUrl}customDocList/list`);
  }
  getCustomDocListNew(projectId) {
    return this.http.get(`${this.baseApiUrl}customDocList/listbyProject/${projectId}`);
  }
  getDocNameByDocId(data) {
    const url = `${this.baseApiUrl}/getDocNameByDocId`;
    return this.http.post(url, data);
  }
  getAllMajors(){
    let url = `${environment.url_v1}majors`;
    return this.http.get(url);
  }
  getAllTerms(){
    let url = `${environment.url_v1}terms`;
    
    return this.http.get(url);
  }
}
