import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import CallLogs from "./models/calllogs-model";
import { BehaviorSubject, Subject } from "rxjs";
import { TicketsService } from "../pages/tickets/tickets.service";
import { GroupService } from "../pages/group.service";
import { HttpClientService } from "../httpclient.service";
import {
  CreateAutoEmail,
  InputCallData,
  InputSMSData,
  MarketingService,
} from "../pages/marketing/marketing.service";
import * as moment from "moment";
import { RoleService } from "../pages/role-management/role.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { FormBuilder, Validators } from "@angular/forms";
import { Comments } from "xlsx/types";
import { CreateAgent } from "../pages/create-agent/create-agent.service";

interface IntializeChat {
  token: string;
  identity: string;
}

interface ChatTransfer {
  to: String; // queue name selected for transferring the call
  workerSid: String;
  ChannelSid: String;
  From: String;
  taskSid: String;
}

interface AgentChat {
  to: string;
  from: string;
}

interface AgentEndChat {
  to: string;
  from: string;
  ChannelSid: string;
}

interface AddAgentChannel {
  to: string;
  ChannelSid: string;
}

interface RemoveAgent {
  identity: string;
  ChannelSid: string;
}

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  twilioApiUrl: any = environment.twilioApiUrl;
  baseApiUrl2: any = environment.baseApiUrl2;
  sockerUrl: any= environment.socketUrl;
  chatChannelSid = new BehaviorSubject<string>("");
  initializeChat = new BehaviorSubject<IntializeChat>({
    token: "",
    identity: "",
  });
  workerJs = new BehaviorSubject<any>("");
  newTask = new BehaviorSubject<any>("");
  userData = JSON.parse(localStorage.getItem("userData"));
  RolePermission = localStorage.getItem("RolePermission");
  userGroups;
  tasks = new BehaviorSubject<any[]>([]);
  prevTasks = new BehaviorSubject<any[]>([]);
  openChat = new Subject<boolean>();
  agentChats = new BehaviorSubject<any[]>([]);  
  postComments = new BehaviorSubject<any>(null);  
  currentStatus = new BehaviorSubject<any>(null);
  unReadCount = new BehaviorSubject<number>(0);
  callAgent = new Subject<String>();
  addAgent = new Subject();
  notificationCount = new BehaviorSubject<number>(0);
  closeSideParent = new BehaviorSubject<any>(null);
  marketingCall = new BehaviorSubject<{
    id: string;
    number: string;
    name: string;
    email: string;
    comments: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
  }>(null);
  marketingCallDocs = new BehaviorSubject<{
    id: string;
    number: string;
    name: string;
    email: string;
    comments: string;
    module: string;
    city: string;
    nextCaller: any;
    lead: any;
    item: any;
  }>(null);
  callMarketingStatus = new BehaviorSubject<{
    id: string;
    status: string;
    calledBy: string;
    followUpdate: Date;
  }>({ id: "", status: "", calledBy: "", followUpdate: new Date() });
  callEnd = new Subject<any>();
  stopCalls = new BehaviorSubject<any>(null);
  startCalls = new BehaviorSubject<string>(null);
  redialContact = new BehaviorSubject<Boolean>(null);
  getCallSid= new BehaviorSubject<any>(null);
  toggleSideBar = new Subject<any>();

  roleAgents: any[] = [];
  selectedAgent: any[] = [];
  projectAgents: any[] = [];
  projectSelectedAgent: any[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    allowSearchFilter: true,
    closeDropDownOnSelection: true,
    textField: "Name",
    idField: "value",
  };
  twilioNumber: any = "+14847873052";
  bodymsg =
    "Hi {{student_name}}, \n\n\
   Thanks for contacting University hub. I am Kunal, an educational adviser at University hub \n\n\
   Your appointment for the telephonic counseling session has been scheduled for {{dt}}  \n\
   Counselor contact number: 7337368804 \n\
   Counselor email address - syeda@universityhub.com\n\
   Kunal Mahtani \n\
   Student Advisor \n\
   T: +1(917) 900 1655 , +1(646) 655 0646 \n\
   E: kunal@universityhub.com \nwww.UniversityHub.com \n";


  commentsForm = this.formBuilder.group({
    name: [""],
    emailId: [""],
    state: [""],
    altContactNumber: [""],
    existingCounseling: [""],
    courseInterested: [""],
    qualification: [""],
    IELETS: [""],
    TOEFL: [""],
    Dulingo: [""],
    PTE: [""],
    countryPreference: [""],
    planning: [""],
    workExperience: [""],
    fundingResource: [""],
    passport: [""],
    visa: [""],
    intakePreferred: [""],
    dob: [new Date()],
    counsellingDate: [""],
    callBack: [""],
    interested: [""],
    assignedTo: [""],
    futureLead: [""],
    comments: [""],
    phoneNumber: [""],
    countryCode: [""],
  });
  submitted: boolean = false;
  projectChange = new BehaviorSubject<any>(null);
  constructor(
    public http: HttpClientService,
    public https: HttpClient,
    private TicketService: TicketsService,
    private groupService: GroupService,
    private roleService: RoleService,
    private marketingService: MarketingService,
    private readonly formBuilder: FormBuilder,
    private readonly agentService: CreateAgent
  ) {

  }

  setupTwilio(companyId) {
    return this.https
      .post(
        `${this.twilioApiUrl}${companyId}/agents/login`,
        {
          worker: {
            SID: JSON.parse(localStorage.getItem("userData")).workerSId,
          },
        },
        { responseType: "text" }
      )
      .toPromise()
      .then((res: any) => {
        let resp = JSON.parse(res);
        this.initializeChat.next({
          token: resp.tokens.access,
          identity: resp.worker.friendlyName,
        });
        return res;
      })
      .catch(this.handleError);
  }

  getConferenceData(callSid, companyId) {
    return this.http
      .get(`${this.twilioApiUrl}${companyId}/phone/call/${callSid}/conference`)
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  transferCall(callSid, body, companyId) {
    return this.http
      .post(`${this.twilioApiUrl}${companyId}/phone/transfer/${callSid}`, body)
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  holdCall(body, companyId) {
    return this.http
      .post(`${this.twilioApiUrl}${companyId}/phone/hold/`, body)
      .toPromise()
      .then((res) => res)
      .catch(this.handleError);
  }

  handleError(error: any): Promise<any> {
    if (error.status === 500) {
      return Promise.reject(error);
    }
    if (error.status === 401) {
      // localStorage.clear();
      return Promise.reject(error);
    }
    return Promise.reject(error.message || error);
  }

  getCustomerChannelSid(channelSid) {
    
    return this.http
      .get(`${this.baseApiUrl2}/webChatByChannel/${channelSid}`)
      .pipe(
        map((data) => {
    
          if(data){
          return {
            reportedby: data["UserName"],
            detail: data["RequestDescription"],
            desc: data["RequestDescription"],
            latestupdate: data["RequestDescription"],
            mail: data["EmailId"],
            updatedby: data["UserName"],
            ticketNumber: data["TicketId"],
            phoneNumber: data["Phone"],
            TicketId: data["TicketId"],
          };
        }
        else{
          return
        }
        })
      );
  }

  getCallHistory(workerSid) {
    return this.http.get<CallLogs[]>(
      `${this.baseApiUrl2}callsByAgent/${workerSid}`
    );
  }

  chatCreateTicket(ticket: any) {
    ticket["priority"] = "Priority3";
    ticket["urgency"] = "Low";
    ticket["status"] = "open";
    ticket["ticketChannel"] = "chat";
    ticket["assignedto"] = JSON.parse(localStorage.getItem("userData")).mail;
    this.groupService.getAgentGroups(this.userData["id"]).subscribe((data) => {
      this.userGroups = data[0]["Name"];
      ticket["group"] = this.userGroups;
      ticket["WorkerSid"] = JSON.parse(
        localStorage.getItem("userData")
      ).workerSId;
      return this.TicketService.createTicket(ticket).subscribe((ticketdata) => {
      });
    });
  }

  updateChatConverstaions(body: object) {
    return this.http.post(`${this.baseApiUrl2}updateWebChat`, body);
  }

  transferChat(body: ChatTransfer, companyId) {
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/messaging-adapter/transfer`,
      body
    );
  }

  agentChatStart(body: AgentChat, companyId) {
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/agent-chat/start`,
      body
    );
  }

  agentChatend(body: AgentEndChat, companyId) {
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/agent-chat/end`,
      body
    );
  }

  agentPreviousChannels(body: { identity: string }, companyId) {
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/agent-chat/agentChannels`,
      body
    );
  }

  addAgentToChannel(body: AddAgentChannel, companyId) {
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/agent-chat/addAgentToGroup`,
      body
    );
  }

  removeAgent(body: RemoveAgent, companyId) {
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/agent-chat/removeAgent`,
      body
    );
  }

  ActiveChatCount(body: { sid: string }) {
    return this.http.post(`${this.baseApiUrl2}webChatCount`, body);
  }
  addParticipantToConv(body: { identity: string, ConversationSid: string}, companyId) {
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/messaging-adapter/addParticipant`,
      body
    );
  }
  SmsStart(
    body: {
      to: string; // user number
      from: string;
      companyId: string;
      message: string;
    },
    companyId
  ) {
    return this.http.post(
      `${this.twilioApiUrl}${companyId}/messaging-adapter/outboundSMS`,
      body
    );
  }

  updateCallComments(body: {
    id: string;
    agentMail: string,
    comments: string;
    followUpdate: Date | string;
    callStatus: string;
    futureLead: string;
    assignedTo: any;
  }) {
    if (body["followUpdate"]) {
      body["followUpdate"] = moment(
        body.followUpdate,
        "YYYY-MM-DDTHH:mm:SSZ"
      ).format("YYYY-MM-DDTHH:mm:SSZ");
    }
    return this.http.post(`${this.baseApiUrl2}updateCallComments`, body);
  }

  updateCallCommentsNew(data) {
    return this.http.post(`${this.baseApiUrl2}updateCallCommentsNew`, data);
  }

  async scheduleDemo(
    projectId,
    marketingId: {
      id: string;
      status: string;
      phoneNumber: string;
      email: string;
      name: string;
      module: string;
    },
    followUpdate,
    comments,
    type
  ) {
    // this.isdemoScheduled = true;
    let num = Math.floor(Math.random() * 90000) + 10000;
    let room = "live-" + num.toString();
    let demoScheduled = `${this.sockerUrl}videocalling/mainscreen/${projectId}/${room}`;

    const infoMarketingBefore16hr: InputCallData = {
      ProjectId: projectId,
      RecipientNumber: marketingId.phoneNumber.replace(/\n/g, ""),
      ScheduledCallTime: moment(followUpdate, "YYYY-MM-DDTHH:mm:SSZ").format(
        "YYYY-MM-DDTHH:mm:SSZ"
      ),
      Type: "Feedback",
      CallerId: "+919398913602",
      Module: marketingId.module,
    };
    let smsId = null;
    let callId = null;
    let mailId = null;

    const mailRemainder: CreateAutoEmail = {
      ProjectId: projectId,
      Module: marketingId.module,
      ScheduledEmailTime: moment(followUpdate, "YYYY-MM-DDTHH:mm:SSZ").format(
        "YYYY-MM-DDTHH:mm:SSZ"
      ),
      RecipientEmail: marketingId.email,
      Type: "Feedback",
    };

    const smsMarketingBefore6hr: InputSMSData = {
      ProjectId: projectId,
      Module: marketingId.module,
      RecipientNumber: marketingId["phoneNumber"].replace(/\n/g, ""),
      ScheduledSMSTime: moment(followUpdate, "YYYY-MM-DDTHH:mm:SSZ").format(
        "YYYY-MM-DDTHH:mm:SSZ"
      ),
      Type: "Information",
    };

    this.marketingService
      .getAutoDialCompany(projectId)
      .subscribe(async (data) => {
        if (data) {
          let apiKey = data["API_KEY"];

          callId = await this.marketingService.createAutoCallScheduler(
            infoMarketingBefore16hr,
            apiKey
          );

          smsId = await this.marketingService.createAutoSmsScheduler(
            smsMarketingBefore6hr,
            apiKey
          );

          mailId = await this.marketingService.createAutoEmail(
            mailRemainder,
            apiKey
          );

          if (type === "demo") {
            setTimeout(() => {
              this.marketingService
                .createDemo({
                  ProjectId: projectId,
                  Name: marketingId.name,
                  // ProspectId: this.marketingId,
                  AutoEmailId: mailId["mailId"],
                  AutoCallId: callId["callId"],
                  AutoSMSId: smsId["smsId"],
                  Comments: comments,
                  DateTime: followUpdate,
                  Presenter: this.userData["mail"],
                  Feedback: "demo-followup",
                  Url: demoScheduled + "/" + this.userData.workerFriendlyName,
                  ClientURL: demoScheduled + "/" + marketingId.name,
                  RoomName: room,
                  LeadId: marketingId.id,
                  agentEmail: this.userData.mail,
                })
                .subscribe((demo) => {
                  this.marketingService
                    .updateDemo({
                      demoId: demo["id"],
                      leadId: marketingId.id,
                    })
                    .subscribe((data) => {
                      // this.demoMail({
                      //   marketingId: marketingId,
                      //   followUpdate,
                      //   demoScheduled,
                      //   projectId,
                      // });
                    });
                });
            }, 3000);
          } else {
            // this.demoMail({
            //   marketingId,
            //   followUpdate,
            //   demoScheduled,
            //   projectId,
            // });
          }
        }
      });
  }

  demoMail({ marketingId, followUpdate, demoScheduled, projectId, name }) {
    this.marketingService.sendMailForDemo(
      {
        name: marketingId.name,
        demoDate: moment(followUpdate).format("MMMM Do YYYY, h:mm:ss a"),
        url: demoScheduled + "/" + marketingId.name,
      },
      marketingId.email,
      projectId,
      name
    );
  }

  updateCallSid(body: { id: string; sid: string }) {
    return this.http.post(`${this.baseApiUrl2}updateCallSid`, body);
  }

  updateCallStatus(data) {
    return this.http.post(`${this.baseApiUrl2}updateCallStatus`, data)
  }

  getRoleAgents(projectId) {
    this.roleService
      .getRoleAgents({ projectId: projectId, name: "counselling" })
      .pipe(
        map((data) => {
          let modifiedData = data.map((user) => ({
            Name: user["FirstName"] + user["LastName"] || "",
            value: user["Mailid"],
          }));
          return modifiedData;
        })
      )
      .subscribe((data) => {
        this.roleAgents = data;
      });
  }

  onReset(): void {
    this.commentsForm.reset();
    this.commentsForm.patchValue({
      visa: "",
      passport: "",
      existingCounseling: "",
      interested: "",
      futureLead: "",
    });
    this.submitted = false;
    this.selectedAgent = [];
  }

  get commentsValue() {
    return this.commentsForm.value;
  }

  updateComments(id) {
    let callbackDate = null;
    let counsellingDate = null;
    if (this.commentsValue.callBack) {
      callbackDate = moment
        .utc(this.commentsValue.callBack)
        .utcOffset(0, true)
        .format();
    }
    if (this.commentsValue.counsellingDate) {

      counsellingDate = moment
        .utc(this.commentsValue.counsellingDate)
        .utcOffset(0, true)
        .format();
    }
    if(this.commentsValue.dob == 'Invalid date'){
      this.commentsValue.dob =  moment(new Date())
      .format('YYYY-MM-DD')
    }
    let comment: any = {
      LeadId: id,
      Name: this.commentsValue.name,	
      Email: this.commentsValue.emailId,
      State: this.commentsValue.state,
      AltContactNo: this.commentsValue.altContactNumber,
      ExistingCounselling: this.commentsValue.existingCounseling,
      InterestedCourse: this.commentsValue.courseInterested,
      IELTS: this.commentsValue.IELETS,
      TOEFL: this.commentsValue.TOEFL,
      PTE: this.commentsValue.PTE,
      Duolingo: this.commentsValue.Dulingo,
      AppearTest: this.commentsValue.planning,
      CountryPreferrence: this.commentsValue.countryPreference,
      WorkExperience: this.commentsValue.workExperience,
      FundingSource: this.commentsValue.fundingResource,
      Passport: this.commentsValue.passport,
      Visa: this.commentsValue.visa,
      IntakePreferrence: this.commentsValue.intakePreferred,
      DOB: this.commentsValue.dob,
      CounsellingDT: counsellingDate,
      CallbackDT: callbackDate,
      Interested: this.commentsValue.interested,
      Other: "",
      FutureLead: this.commentsValue.futureLead,
      CountryCode: this.commentsValue.countryCode,
      PhoneNumber: this.commentsValue.phoneNumber,
      Education: this.commentsValue.qualification,
    };
    return this.http.post(`${this.baseApiUrl2}createComment`, comment);
  }

  getCommentLead(leadId) {
    return this.http.get(`${this.baseApiUrl2}getCommentByLead/${leadId}`).pipe(
      map((data) => {
        if (data) {
          this.commentsForm.patchValue({
            state: data["State"] || "",
            altContactNumber: data["AltContactNo"] || "",
            existingCounseling: data["ExistingCounselling"] || "",
            courseInterested: data["InterestedCourse"] || "",
            IELETS: data["IELTS"] || "",
            TOEFL: data["TOEFL"] || "",
            PTE: data["PTE"] || "",
            Dulingo: data["Duolingo"] || "",
            planning: data["AppearTest"] || "",
            countryPreference: data["CountryPreferrence"] || "",
            workExperience: data["WorkExperience"] || "",
            fundingResource: data["FundingSource"] || "",
            passport: data["Passport"] || "",
            visa: data["Visa"] || "",
            intakePreferred: data["IntakePreferrence"] || "",
            dob: data["DOB"] ? this.dateFormatForAddUpdate1(data["DOB"]): null,
            counsellingDate: data["CounsellingDT"] || "",
            callBack: data["CallbackDT"] || "",
            interested: data["Interested"] || "interested",
          });
          return data;
        }
      })
    );
  }

  sendRemaindersAgent({ projectId, date, mail, module }) {
    this.http
      .get(`${this.baseApiUrl2}getAgentEmail/${mail}`)
      .subscribe((data) => {
        this.scheduleDemo(
          projectId,
          {
            id: data["id"],
            status: "",
            phoneNumber: data["PhoneNumber"],
            email: data["Mailid"],
            name: data["FirstName"],
            module: module,
          },
          date,
          "",
          "followup"
        );
      });
  }

  updateCommentsBasedOnId(id) {
    let callbackDate = null;
    let counsellingDate = null;
    let dateofbirth = null;
    if (this.commentsValue.callBack) {
      callbackDate = moment
        .utc(this.commentsValue.callBack)
        .utcOffset(0, true)
        .format();
    }

    if (this.commentsValue.counsellingDate) {
      counsellingDate = moment
        .utc(this.commentsValue.counsellingDate)
        .utcOffset(0, true)
        .format();
    }

    if (this.commentsValue.dob) {
      dateofbirth = moment
        .utc(this.commentsValue.dob)
        .utcOffset(0, true)
        .format();

    } else {

    }
    let comment: any = {
      LeadId: id,
      Name: this.commentsValue.name,
      Email: this.commentsValue.emailId,
      State: this.commentsValue.state,
      AltContactNo: this.commentsValue.altContactNumber,
      ExistingCounselling: this.commentsValue.existingCounseling,
      InterestedCourse: this.commentsValue.courseInterested,
      IELTS: this.commentsValue.IELETS,
      TOEFL: this.commentsValue.TOEFL,
      PTE: this.commentsValue.PTE,
      Duolingo: this.commentsValue.Dulingo,
      AppearTest: this.commentsValue.planning,
      CountryPreferrence: this.commentsValue.countryPreference,
      WorkExperience: this.commentsValue.workExperience,
      FundingSource: this.commentsValue.fundingResource,
      Passport: this.commentsValue.passport,
      Visa: this.commentsValue.visa,
      IntakePreferrence: this.commentsValue.intakePreferred,
      DOB: dateofbirth,
      CounsellingDT: counsellingDate,
      CallbackDT: callbackDate,
      Interested: this.commentsValue.interested,
      Other: "",
      futureLead: this.commentsValue.futureLead,
      CountryCode: this.commentsValue.countryCode,
      PhoneNumber: this.commentsValue.phoneNumber,
      Education: this.commentsValue.qualification,
    };
    return this.http.post(`${this.baseApiUrl2}updateLeadComment`, comment);
  }

  sendSmsForLead({ student_name, date, from, tosms, projectId }) {
    let bodymsg = this.bodymsg;
    let datas = {
      student_name: student_name,
      dt: date,
    };
    const result = bodymsg.replace(/{{([^{{]+)}}/g, (match, key) => {
      return datas[key] !== undefined ? datas[key] : "";
    });
    this.marketingService
      .sendSms({ from: from, to: tosms, text: result }, projectId)
      .subscribe();
  }

  async getContacts(projectId) {
    if (await this.userData) {
      this.agentService
        .getContacts(projectId, this.userData.id)
        .pipe(
          map((data) => {
            return data.map((agent) => ({
              Name: agent["FirstName"],
              value: agent["workerFriendlyName"],
            }));
          })
        )
        .subscribe((data) => {
          this.projectAgents = data;;
        });
    }
  }

  dateFormatForAddUpdate(date) {
    return moment
      .utc(date)
      .utcOffset(0, true)
      .format('YYYY-MM-DDTHH:mm:ss');
  }
  dateFormatForAddUpdate1(date) {
    return moment(date)
      .format('YYYY-MM-DD');
  }
}
