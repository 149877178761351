import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../auth.service";
// import { HttpClientService } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { MailserviceService } from "src/app/pages/mailservice.service";
import { HttpClientService } from "src/app/httpclient.service";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { CreateAgent } from "src/app/pages/create-agent/create-agent.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { ProjectService } from "src/app/pages/project-management/project.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public baseUrl = environment.baseApiUrl;
  forgetpassword = true;
  loginForm: FormGroup;
  submitted = false;
  returnUrl: string;
  error = "";
  loading: boolean = false;
  showErrorMessage: any = false;
  errorMessage: any;
  userData: any;
  email: any;
  selectIndex: number = 0;
  template: any;
  random = Math.random().toString(36).substring(7);
  compareNumber: any;
  resetPassword: boolean = false;
  fcmtoken: string;
  fieldTextType: boolean;
  fp = "fp1";

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private http: HttpClientService,
    private agentService: CreateAgent,
    private mailService: MailserviceService,
    private handleError: ErrorHandlerService,
    private projectService: ProjectService
  ) {}

  ngOnInit() {
    this.requestPermission();
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
    this.mailService
      .getTemplateCompany(
        "passwordverification",
        environment.forgotPassEmailTempId
      )
      .subscribe(
        (data) => {
          this.template = data["Html"];
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
    this.agentService
      .updateStatus({
        id: this.userData["id"],
        status: "online",
        fcmtoken: this.userData.fcmtoken,
        token: this.userData.token,
      })
      .subscribe(
        (data) => {},
        (error) => {
          this.handleError.handleError(error);
        }
      );
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
  }

  ngAfterViewInit() {
    document.body.classList.add("authentication-bg");
    document.body.classList.add("authentication-bg-pattern");
  }
  get f() {
    return this.loginForm.controls;
  }

  onForget(f) {
    if (f.newPassword !== f.confirmPassword) {
      return (this.error =
        "please enter new password and confirm password as same");
    }
    this.authService
      .changePassword({
        mail: this.email,
        newPassword: f["newPassword"],
      })
      .subscribe(
        (data) => {
          this.forgetpassword = !this.forgetpassword;
          this.error = "";
        },
        (error) => {
          this.error = "please enter valid email or password";

          this.handleError.handleError(error);
        }
      );
  }

  onSubmit = () => {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;
    const body = {
      email: this.f.email.value,
      password: this.f.password.value,
    };

    this.http
      .post(`${this.baseUrl}agentsignin`, {
        Mailid: body.email,
        password: body.password,
        fcmtoken: this.fcmtoken,
      })
      .subscribe(
        (res) => {
          if (res !== null) {
            if (res["ResetPassword"]) {
              this.email = res["mail"];
              this.forgetpassword = false;
              return (this.resetPassword = true);
            }
            this.userData = res;
            this.userData.fcmtoken = this.fcmtoken;
          }
          if (!this.userData.status) {
            localStorage.setItem("userData", JSON.stringify(this.userData));

            this.authService.getUserRoles(res["id"]).subscribe(
              (data) => {
                let values: any[] = data.map((data) => data["Name"]);
                this.authService.admin.next(values.includes("Admin"));
              },
              (error) => {
                this.handleError.handleError(error);
              }
            );
            let admin: boolean = false;
            let userData = JSON.parse(localStorage.getItem("userData"));
            this.projectService
              .dashboardProjects({
                admin: admin,
                companyId: this.userData["companyId"],
                userId: this.userData["id"],
              })
              .subscribe((data: any) => {
                if (data.length === 1) {
                  switch (data[0].Name) {
                    case "University Hub":
                      this.router.navigate(["/socialmedia/leads"]);
                      break;
                    case "Referrals":
                      this.router.navigate(["/referree"]);
                      break;
                    case "Campbellsville":
                      this.router.navigate(["/uhleads"]);
                      break;
                    case "Recruiter":
                      this.router.navigate(["/recruiter"]);
                      break;
                    case "CuNext":
                      this.router.navigate(["/cunext"]);
                      break;
                    case "SFC":
                      this.router.navigate(["/sfcleads"]);
                      break;
                    default:
                      this.router.navigate(["/uhleads"]);
                      break;
                  }
                } else {
                  this.router.navigate(["/uhleads"]);
                }
              });
            // window.location.reload();
          } else {
            this.error = `${this.userData.status}`;
          }
        },
        (error) => {
          this.handleError.handleError(error);
        }
      );
  };

  passwordChange() {
    if (this.error.length !== 0) {
      this.error = "";
    }
  }
  checkEmail() {
    this.authService.checkEmail({ mail: this.email }).subscribe(
      (data) => {
        let replacements = {
          code: this.random,
          FirstName: data["firstName"],
          LastName: data["lastName"],
        };
        const result = this.template.replace(/{{([^{{]+)}}/g, (match, key) => {
          return replacements[key] !== undefined ? replacements[key] : "";
        });

        this.mailService
          .sendmail(
            {
              From: {
                email: "no-reply@universityhub.com",
                name: "University Hub",
              },
              To: `${this.email}`,
              Subject: "sent verfication code",
              Body: {
                html: result,
              },
              ReplyTo: "no-reply@universityhub.com",
            },
            1
          )
          .subscribe((data) => {
            this.fp = "fp2";
          });
        this.selectIndex += 1;
        this.error = "";
      },
      (error) => {
        this.error = `${error.error.status}`;
        this.handleError.handleError(error);
      }
    );
  }

  comparePassword() {
    if (this.compareNumber === this.random) {
      this.selectIndex += 1;
      this.error = "";
      this.fp = "fp3";
    } else {
      this.error = "please enter the valid verification code";
    }
  }
  resetForm(value) {
    if (value.newPassword === value.confirmPassword) {
      this.authService
        .forgetPassword({
          newPassword: value.newPassword,
          mail: this.email,
          oldPassword: value.oldPassword,
        })
        .subscribe(
          (data) => {
            this.forgetpassword = true;
            this.resetPassword = false;
          },
          (error) => {
            this.error = error.error.status;
            setTimeout(() => {
              this.error = "";
            }, 1000);
            this.handleError.handleError(error);
          }
        );
    } else {
      this.error = "Please Enter Confirm Password and New Password as Same";
      setTimeout(() => {
        this.error = "";
      }, 1000);
    }
  }

  cancel() {
    this.selectIndex = 0;
    this.forgetpassword = !this.forgetpassword;
  }

  requestPermission() {
    const messaging = getMessaging();
    // GET TOKEN FROM FIREBASE
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          this.fcmtoken = currentToken;
        } else {
        }
      })
      .catch((err) => {});
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
