import { ManagementService } from "../../pages/management.service";
import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/account/auth/auth.service";
import { LayoutService } from "../layout.service";
import { Router } from "@angular/router";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { SharedService } from "src/app/shared/shared.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  panelExpanded1: false;
  userData: any;
  displayMenus: any[] = [];
  show = true;

  constructor(
    private authService: AuthService,
    private managementService: ManagementService,
    private readonly layoutService: LayoutService,
    private handleError: ErrorHandlerService,
    public readonly router: Router,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.layoutService.closeSideParent.subscribe(()=> {
      $(".show").removeClass("show")
    });
    this.sharedService.switchToggle.subscribe((data) => {
      if(data){
        this.show = !this.show;
      }
    })
    this.authService.getUserRoles(this.userData.id).subscribe((data) => {
      let values: any[] = data.map((data) => data["Name"].toLowerCase());
      let roleIds: any[] = data.map((data) => data["id"]);
      if (values.includes("admin")) {
        this.authService.companyAdmin.next(true);
      } else {
        this.authService.companyAdmin.next(false);
      }
      this.managementService
        .getSideMenuRoles({
          roles: roleIds,
          superAdmin: this.userData["companyId"] === 0 ? true : false,
        })
        .subscribe((data) => {
          this.displayMenus = data;
          this.sharedService.displayMenu.next(this.displayMenus)
        }, (error) => {
          this.handleError.handleError(error)
        });
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  checkingIdMenu(id): boolean {
    
    return this.displayMenus.find((val) => val["id"] === id);
  }

  getMenuName(id): string {
    let item = this.displayMenus.find((val) => val["id"] === id);
    return item["Name"] || "";
  }
  toggleSideBar() {
    console.log("toggle")
    this.layoutService.toggleSideBar.next('hello')
  }
}
