import { AgmCoreModule } from "@agm/core";
import { PagesModule } from "./pages/pages.module";
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LayoutModule } from "./layout/layout.module";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LandingpageComponent } from "./landingpage/landingpage.component";
import { PrivacypolicyComponent } from "./privacypolicy/privacypolicy.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library }  from '@fortawesome/fontawesome-svg-core';
// import { MatIconModule } from "@angular/material";
import { faCalendar, faClock } from "@fortawesome/free-solid-svg-icons";
import { MailResponseComponent } from "./mail-response/mail-response.component";
import { BnNgIdleService } from "bn-ng-idle";
import { UhleadsComponent } from "./pages/uhleads/uhleads.component";
import { CuNextComponent } from "./pages/cu-next/cu-next.component";
import { DemoMaterialModule } from "./pages/uhleads/material.module";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ToastrModule } from "ngx-toastr";
import { MatDialogModule } from "@angular/material/dialog";
import * as $ from "jquery";
import {NgxDocViewerModule} from 'ngx-doc-viewer'
import { NgxTimerModule } from 'ngx-timer';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { SimpleNotificationsModule } from "./notificaton.module";
import { initializeApp } from 'firebase/app';
import { environment } from "src/environments/environment";
import {ActivityFilterPipe} from 'src/app/shared/activity.pipe'
import { MatIconModule } from "@angular/material/icon";
import { PaginationDirective } from "./shared/custom-pagination/pagination.directive";
import { CustomPaginationComponent } from "./shared/custom-pagination/custom-pagination.component";
import { ErrorHandlerService } from "./error-handler.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { EnquiryComponent } from "./pages/enquiry/enquiry.component";
import { MatSelectModule } from "@angular/material/select";
import { MaterialExampleModule } from "./material.module";
import { UhubDashboardComponent } from "./pages/uhub-dashboard/uhub-dashboard.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { JudsonuLeadsComponent } from './pages/judsonu-leads/judsonu-leads.component';
import { SfcLeadsComponent } from './pages/sfc-leads/sfc-leads.component';
import { UwlaComponent } from './pages/uwla/uwla.component';
import { SocialmedialeadsComponent } from "./pages/uhub-dashboard/socialmedialeads/socialmedialeads.component";
import { RecruiterleadsComponent } from "./pages/uhub-dashboard/recruiterleads/recruiterleads.component";
import { CustomPaginationuhubComponent } from "./shared/custom-paginationuhub/custom-paginationuhub.component";

initializeApp(environment.firebase);


@NgModule({
  declarations: [
    AppComponent,
    LandingpageComponent,
    PrivacypolicyComponent,
    MailResponseComponent,
    UhleadsComponent,
    CuNextComponent,
    UhubDashboardComponent,
    SocialmedialeadsComponent,
    RecruiterleadsComponent,
    EnquiryComponent,
    ActivityFilterPipe,
    PaginationDirective,
    CustomPaginationComponent,
    CustomPaginationuhubComponent,
    JudsonuLeadsComponent,
    SfcLeadsComponent,
    UwlaComponent
  ],
  imports: [
    BrowserModule,
    LayoutModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PagesModule,
    FormsModule,
    FontAwesomeModule,
    MatIconModule,
    NgbModule,
    DemoMaterialModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    SimpleNotificationsModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAYClvVNn_82-k1LOLLsBTz_O12v65MhdY",
      libraries: ["places"],
    }),
    MatDialogModule,
    ToastrModule.forRoot(),
    NgxDocViewerModule,
    NgxTimerModule,
    IvyCarouselModule,
    MaterialExampleModule,
    NgxSkeletonLoaderModule
    ],
  providers: [BnNgIdleService, { provide: ErrorHandler, useClass: ErrorHandlerService },],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
  constructor() {
    library.add(faCalendar, faClock);
  }
}
