import { AuthService } from "src/app/account/auth/auth.service";
import { SharedService } from "./../../shared/shared.service";
import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { CreateAgent } from "./create-agent.service";
import { Group } from "./models/group.model";
import { Role } from "./models/role.model";
import { Agent } from "./models/agent.model";
import { MailserviceService } from "../mailservice.service";
import { RoleService } from "../role-management/role.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Subscription } from "rxjs";
import { ProjectService } from "../project-management/project.service";
import { ProjectchangeService } from "src/app/layout/project-change/projectchange.service";
import { ErrorHandlerService } from "src/app/error-handler.service";
import { CommonServicesService } from "src/app/common-services.service";

interface PendingSelection {
  [key: number]: boolean;
}

@Component({
  selector: "app-create-agent",
  templateUrl: "./create-agent.component.html",
  styleUrls: ["./create-agent.component.less"],
})
export class CreateAgentComponent implements OnInit {
  closeResult = "";
  public Roles: Role[];
  public pendingSelection: PendingSelection;
  public selectedRoles: Role[];
  public unselectedRoles: Role[];
  public groups: Group[] = [];
  public pendingselectiongroup: PendingSelection;
  public selectedGroups: Group[];
  public unselectedGroups: Group[];
  public agents: Agent[] = [];
  public page: any = 1;
  public pageSize: any = 9;
  public isUpdate: boolean = false;
  public FirstName = "";
  public LastName = "";
  public Mailid = "";
  public PhoneNumber = "";
  public error = "";
  public isAdminRole: boolean;
  public companyId: any;
  public companies: any[] = [];
  public companySettings: IDropdownSettings;
  public selectedProjects: any[] = [];
  public unselectedProjects: any[] = [];

  public iserror: boolean = false;
  public ActiveStatus: string;
  public search: string;
  public template: any;
  public superAdmin: Boolean = false;
  public userData: any;
  public agentSubscription: Subscription;
  public order: boolean = false;
  public orderFeild: string = "UpdatedDate";
  public orderString: boolean | "asc" | "desc" = "desc";
  public validation: boolean = false;
  public projects: any[] = [];
  public projectId: any;

  countries = [];
  selectedCountryCode = 1;
  deleteItem: any;
  sideViewerData: any;
  minItems = 5;
  activityfilterString: string = "";

  selectCountry(country) {

    this.selectedCountryCode = country.code;
  }

  constructor(
    private modalService: NgbModal,
    private AgentService: CreateAgent,
    private SharedService: SharedService,
    private mailService: MailserviceService,
    private roleService: RoleService,
    private authService: AuthService,
    private projectService: ProjectService,
    private handleError: ErrorHandlerService,
    public projectchangeService: ProjectchangeService,
    public commonService: CommonServicesService
  ) { }

  ngOnInit() {
    this.fetchCountries();
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.companySettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      textField: "Name",
    };

    this.roleService.getCompanies().subscribe((companies) => {
      this.companies = companies;
      this.companyId = [
        this.companies.find((company) => company["id"] === this.userData.id),
      ];
      this.SharedService.CompanyId.subscribe((data) => {
        if (this.userData.companyId === 0) {
          this.companyId = [
            this.companies.find((company) => company["id"] === +data),
          ];

          this.projectService
            .getProjects(this.companyId[0]["id"])
            .subscribe((data) => {
              this.unselectedProjects = data;
              this.projects = data;
            }, (error) => {
              this.handleError.handleError(error)
            });
          this.getRolesGroups();
        } else {
          this.projectId = +data;

          this.projectService
            .dashboardProjects({
              companyId: this.userData.companyId,
              userId: this.userData.id,
              admin: false,
            })
            .subscribe((data) => {
              this.projects = data;
              this.unselectedProjects = data;
              this.getRolesGroups();
            }, (error) => {
              this.handleError.handleError(error)
            });
          this.companyId = [
            this.companies.find(
              (company) => company["id"] === this.userData.companyId
            ),
          ];
        }
      }, (error) => {
        this.handleError.handleError(error)
      });

      setTimeout(() => {
        this.projectId = this.projectchangeService._projectId;
        if (this.userData.companyId === 0) {
          this.getAllAgents();
        } else {
          this.getAllAgents();
        }
      }, 500);
    }, (error) => {
      this.handleError.handleError(error)
    });
    this.SharedService.pageEvent.emit({ pageName: "Agent Management" });
    this.mailService.getTemplateByName("ticket").subscribe((data) => {
      this.template = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  public addToSelectedProject(contact?: any): void {
    let changeRoles = contact
      ? [contact]
      : this.getPendingSelectionFromCollection(this.unselectedProjects);
    this.pendingSelection = Object.create(null);
    this.unselectedProjects = this.removeRolesFromCollection(
      this.unselectedProjects,
      changeRoles
    );
    this.selectedProjects = changeRoles.concat(this.selectedProjects);
    this.getRolesGroups();
  }

  public removeFromSelectedProject(contact?: Role): void {
    let changeRoles = contact
      ? [contact]
      : this.getPendingSelectionFromCollection(this.selectedProjects);
    this.pendingSelection = Object.create(null);
    this.selectedProjects = this.removeRolesFromCollection(
      this.selectedProjects,
      changeRoles
    );
    this.unselectedProjects = changeRoles.concat(this.unselectedProjects);
    this.getRolesGroups();
  }

  public addToSelectedRoles(contact?: Role): void {
    var changeRoles = contact
      ? [contact]
      : this.getPendingSelectionFromCollection(this.unselectedRoles);
    this.pendingSelection = Object.create(null);
    this.unselectedRoles = this.removeRolesFromCollection(
      this.unselectedRoles,
      changeRoles
    );
    this.selectedRoles = changeRoles.concat(this.selectedRoles);
    this.getRoleGroups(this.selectedRoles);
  }

  public removeFromSelectedRoles(contact?: Role): void {
    var changeRoles = contact
      ? [contact]
      : this.getPendingSelectionFromCollection(this.selectedRoles);
    this.pendingSelection = Object.create(null);
    this.selectedRoles = this.removeRolesFromCollection(
      this.selectedRoles,
      changeRoles
    );
    this.unselectedRoles = changeRoles
      .concat(this.unselectedRoles)
      .sort(this.sortContactOperator);
    this.getRoleGroups(this.selectedRoles);
    this.selectedGroups = this.selectedGroups.filter((el) =>
      this.selectedRoles.some((element) => element["id"] === el["RoleId"])
    );
  }

  public togglePendingSelection(contact: Role): void {
    this.pendingSelection[contact["id"]] =
      !this.pendingSelection[contact["id"]];
  }

  private getPendingSelectionFromCollection(collection: any[]): any[] {
    var selectionFromCollection = collection.filter((contact) => {
      return contact["id"] in this.pendingSelection;
    });
    return selectionFromCollection;
  }

  private removeRolesFromCollection(
    collection: any[],
    RolesToRemove: any[]
  ): any[] {
    var collectionWithoutRoles = collection.filter((contact) => {
      return !RolesToRemove.includes(contact);
    });
    return collectionWithoutRoles;
  }

  private sortContactOperator(a: Role, b: Role): number {
    return a["Name"].localeCompare(b["Name"]);
  }

  public addToSelectedGroups(contact?: Group): void {
    var changeGroups = contact
      ? [contact]
      : this.getPendingSelectionFromCollection(this.unselectedGroups);
    this.pendingselectiongroup = Object.create(null);
    if (this.unselectedGroups.length !== 0) {
      this.unselectedGroups = this.removeRolesFromCollection(
        this.unselectedGroups,
        changeGroups
      );
    }
    this.selectedGroups = changeGroups.concat(this.selectedGroups);
  }

  public removeFromSelectedGroups(contact?: Group): void {
    var changeGroups = contact
      ? [contact]
      : this.getPendingSelectionFromCollection(this.selectedGroups);
    this.pendingSelection = Object.create(null);
    this.selectedGroups = this.removeRolesFromCollection(
      this.selectedGroups,
      changeGroups
    );
    this.unselectedGroups = changeGroups
      .concat(this.unselectedGroups)
      .sort(this.sortContactOperator);
  }

  public toggleSelectionGroup(contact: Group): void {
    this.pendingselectiongroup[contact["id"]] =
      !this.pendingselectiongroup[contact["id"]];
  }

  open(content) {
    this.iserror = false;
    this.modalService.open(content, { size: "lg" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    this.getRolesGroups();
    this.FirstName = "";
    this.LastName = "";
    this.PhoneNumber = "";
    this.Mailid = "";
    this.isUpdate = false;
    this.validation = false;
    this.iserror = false;
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  submit(form: NgForm) {
    if (this.selectedGroups.length !== 0 && form.valid) {
      let PhoneNumber;
      const loginUserId = this.userData.id;
      const FirstName = form.value.firstname;
      const LastName = form.value.lastname;
      const Mailid = this.Mailid;
      PhoneNumber = `${this.selectedCountryCode}${form.value.number}`;
      const workerSid = Math.random();
      let ActiveStatus = true;
      let isRoleAdmin = form.value.isRoleAdmin;
      let companyId = this.companyId[0]["id"];
      if (this.isUpdate) {
        PhoneNumber = form.value.number;
      }

      if (this.isUpdate) {
        ActiveStatus = form.value.ActiveStatus === "active" ? true : false;
      }
      const Projects = this.selectedProjects.map((project) => project["id"]);
      const Roles = this.selectedRoles.map((role) => {
        return role["id"];
      });
      const Groups = this.selectedGroups.map((Group) => {
        return Group["id"];
      });

      const createAgent: Object = {
        loginUserId,
        FirstName,
        LastName,
        Mailid,
        PhoneNumber,
        workerSid,
        Roles,
        Groups,
        ActiveStatus,
        isRoleAdmin,
        companyId,
        Projects,
      };
      this.createagent(createAgent);
      this.validation = false;
    } else {
      this.validation = true;
    }
  }

  createagent(body: any) {
    if (this.isUpdate) {
      return this.AgentService.AgentUpdate(body).subscribe((resp) => {
        if (resp) {
          this.getAllAgents();
          this.addAgentActivity({
            userid: this.userData.id,
            usermail: this.userData.mail,
            target_agent: body.Mailid,
            activity_type: 'Update',
            Description: `Agent ${this.userData.firstName || ''} ${this.userData.lastName} updated ${body.FirstName} ${body.LastName}`
          });
        }
        this.modalService.dismissAll();
        this.iserror = false;
      }, (error) => {
        this.handleError.handleError(error)
      });
    }
    this.AgentService.createAgent(body).subscribe(
      (resp: any) => {
        if ("status" in resp) {
          this.iserror = true;
          return (this.error = resp["status"]);
        }
        if (resp) {
          this.getAllAgents();
          this.addAgentActivity({
            userid: this.userData.id,
            usermail: this.userData.mail,
            target_agent: body.Mailid,
            activity_type: 'Create',
            Description: `Agent ${this.userData.firstName || ''} ${this.userData.lastName} created ${body.FirstName} ${body.LastName}`
          });
        }
        this.modalService.dismissAll();
        this.iserror = false;
      },
      (err) => {
        this.iserror = true;
        this.error = "Please Try Again After Sometime";
        setTimeout(() => {
          this.iserror = false;
        }, 10000);
        this.handleError.handleError(err)

      }
    );
  }

  getAllAgents() {
    this.AgentService.GetAllAgents(this.projectId).subscribe((res) => {
      this.agents = res;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  getRolesGroups() {
    if (this.selectedProjects.length !== 0) {
      this.AgentService.getAgentRole({
        projects: this.selectedProjects,
      }).subscribe((res) => {
        let roles = res;
        this.unselectedRoles = roles;
        this.unselectedRoles = roles.filter(
          (el) => !this.selectedRoles.some((ele) => ele["id"] === el["id"])
        );
        this.selectedRoles = this.selectedRoles.filter((el) =>
          this.selectedProjects.some(
            (element) => element["id"] === el["ProjectId"]
          )
        );

      }, (error) => {
        this.handleError.handleError(error)
      });
    } else {
      this.selectedRoles = [];
      this.selectedGroups = [];
      this.unselectedRoles = [];
      this.unselectedGroups = [];
    }
  }

  updateAgent(id: number, content) {
    this.isUpdate = true;
    this.AgentService.GetAgentbyid(id).subscribe((res) => {
      if (content != "") {
        this.open(content);
      }
      this.FirstName = res["FirstName"];
      this.PhoneNumber = res["PhoneNumber"];
      this.LastName = res["LastName"];
      this.Mailid = res["Mailid"];
      this.selectedRoles = res["SelectedRoles"];
      this.selectedGroups = res["SelectedGroups"];
      this.selectedProjects = res["SelectedProjects"];
      this.ActiveStatus =
        res["ActiveStatus"] === "true" ? "active" : "inactive";
      this.isAdminRole = res["IsRoleAdmin"];
      this.companyId = [
        this.companies.find((data) => data["id"] === res["CompanyId"]),
      ];
      this.unselectedProjects = this.projects.filter(
        (el) => !this.selectedProjects.some((ele) => ele["id"] === el["id"])
      );
      this.getRolesGroups();
      let SelectedGroupsids = this.selectedGroups.map((Group) => Group["id"]);
      let AllGroups;
      this.AgentService.getAgentManGroups({
        roles: this.selectedRoles,
      }).subscribe((res) => {
        AllGroups = res;
        this.unselectedGroups = AllGroups.filter(
          (data) => !this.selectedGroups.some((ele) => ele["id"] === data["id"])
        );
      }, (error) => {
        this.handleError.handleError(error)
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }
  openDeleteAgent(item,content){
    this.deleteItem = item;
    this.modalService.open(content, { size: "sm" }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  deleteAgent() {
    this.AgentService.updateAgentStatus({ id: this.deleteItem.id }).subscribe((data) => {
      this.getAllAgents();
      this.modalService.dismissAll();
      this.addAgentActivity({
        userid: this.userData.id,
        usermail: this.userData.mail,
        target_agent: this.deleteItem["Mailid"],
        activity_type: 'Delete',
        Description: `Agent ${this.userData.firstName || ''} ${this.userData.lastName} deleted ${this.deleteItem["FirstName"]} ${this.deleteItem["LastName"]}`
      });
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  cancel() {
    this.modalService.dismissAll();
  }

  companySelect() {
    this.getRolesGroups();
  }

  fetchCountries() {
    this.SharedService.getCountries().subscribe((data) => {
      this.countries = data;
    }, (error) => {
      this.handleError.handleError(error)
    });
  }

  sortOrder(feildName) {
    this.orderFeild = feildName;
    this.order = !this.order;
    if (this.order) {
      this.orderString = "asc";
    } else {
      this.orderString = "desc";
    }
  }

  public getRoleGroups(body: Role[]) {
    return this.AgentService.getAgentManGroups({ roles: body }).subscribe(
      (data) => {
        let groups = this.unselectedGroups;
        this.unselectedGroups = data.filter(
          (el) =>
            !this.selectedGroups.some((element) => element["id"] === el["id"])
        );
      }, (error) => {
        this.handleError.handleError(error)
      });
  }

  public getRoles(projectId) {
  }
  projectChange(data) {
    if (this.userData.companyId === 0) {
      this.projectId = +data;
      this.getAllAgents();
    } else {
      this.projectId = +data;
      this.getAllAgents();
    }
  }

  addAgentActivity(data) {
    this.commonService.addAgentActivity(data).subscribe((data: any) => {

    },(err) => {

    })
  }
  sideNavData(id,email) {
    
    this.activityfilterString = "";
    this.commonService.agentActivityList({id: id.toString(),email: email}).subscribe((data) => {
      this.sideViewerData = data;
    }, (err) => {

    })
  }
  showmore(itemsCount) {
    if (itemsCount < this.minItems) {
    } else if (this.minItems + 5 < itemsCount) {
      this.minItems = this.minItems + 5;
    } else if (this.minItems < itemsCount) {
      this.minItems = itemsCount;
    }
  }
  onInputActivity(event: any) {

    if (this.activityfilterString != "") {
      this.minItems = event;
    } else {
      this.minItems = 5;
    }
  }
  getColorBasedOnType(type: string): string {
    // You can define your logic to determine the color based on the type
    switch (type) {
      case 'Create':
        return '#04745a';
      case 'Delete':
        return '#a52a1e';
      case 'Update':
        return '#013ca3';
      // Add more cases as needed
      default:
        return 'gray';
    }
  }
}
