<mat-sidenav id="sideNav" #sideNav mode="side" class="pr" opened="false" position="end" class="pf w45 z-top">
  <div class="modal-header d-flex pf w45" style="right: 0; top: 0; z-index: 9999">
    <div class="d-flex">
      <div class="close hand" (click)="sideNav.toggle()">
        <span aria-hidden="true">&times;</span>
      </div>
      <div class="modal-title mr-auto">Overview</div>
    </div>
  </div>
  <mat-tab-group class="mt-5 sideDataViewer">
    <mat-tab label="Call Details">
      <div class="alert alert-info mb-0">
        {{ sideViewerData.CallDetails.length }} Calls
      </div>
      <div *ngFor="let i of sideViewerData.CallDetails" class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>Called by</td>
            <td>{{ i.AgentName }}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ i.DateTime | date : "medium" }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <span *ngIf="i.CallStatus == 'pending'">Pending</span>
              <span *ngIf="i.CallStatus == 'uncontactable'">Uncontactable</span>
              <span *ngIf="i.CallStatus == 'notinterested'">Not Interested</span>
              <span *ngIf="i.CallStatus == 'completed'">Completed</span>
              <span *ngIf="i.CallStatus == 'callback'">Call Back</span>
              <span *ngIf="i.CallStatus == 'informed'">Informed</span>
              <span *ngIf="i.CallStatus == 'called'">Called</span>
              <span *ngIf="i.CallStatus == 'callfailed'">Wrong Number</span>
              <span *ngIf="i.CallStatus == 'deferredintake'">Deferred Intake</span>
              <span *ngIf="
                  i.CallStatus == 'callnotanswered' ||
                  i.CallStatus == 'notanswered'
                ">Not Answered</span>
              <span *ngIf="i.CallStatus == 'deferred' || i.CallStatus == 'Deferred'">Deferred Intake</span>
            </td>
          </tr>
          <tr>
            <td>Direction</td>
            <td *ngIf="i.Direction == 'inbound'">InComing Call</td>
            <td *ngIf="i.Direction == 'outbound'">OutGoing Call</td>
            <!-- <td>{{i.Direction ||'-'}}</td> -->
          </tr>
          <tr>
            <td style="padding-top: 20px">Call Recording</td>
            <td>
              <audio *ngIf="i.RecordingURL != '-'" style="height: 2.5rem; width: 15srem" [src]="i.RecordingURL" controls
                controlsList="nodownload" (play)="audioPlay($event)"></audio>
            </td>
          </tr>
          <tr>
            <td>Comments</td>
            <td>{{ i.Comments }}</td>
          </tr>
        </table>
        <hr class="hr-style" />
      </div>
    </mat-tab>

    <mat-tab label="Student Details">
      <div class="mx-2 my-2">
        <table class="table table-borderless table-md caller-table my-1">
          <tr>
            <td>GUID</td>
            <td>{{ sideViewerData.GUID }}</td>
          </tr>
          <tr>
            <td>App Id</td>
            <td>{{ sideViewerData.ApplicationId }}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              {{
              sideViewerData.FirstName + " " + sideViewerData.LastName
              | titlecase
              }}
            </td>
          </tr>
          <tr>
            <td>DOB</td>
            <td>{{ sideViewerData.DOB | date: "dd MMM, yyyy"}}</td>
          </tr>
          <tr>
            <td>Submitted Date</td>
            <td>{{ sideViewerData.createdAt | date : "medium" }}</td>
          </tr>
          <tr>
            <td>APP Status</td>
            <td>{{ sideViewerData.APPStatus | titlecase }}</td>
          </tr>
          <tr>
            <td>Enrollment Year</td>
            <td>{{ sideViewerData.EnrollmentYear || "NA" }}</td>
          </tr>
          <tr>
            <td>Program</td>
            <td>{{ sideViewerData.Program }}</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>
              {{
              "+" + sideViewerData.CountryCode + sideViewerData.PhoneNumber
              }}
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ sideViewerData.Email }}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              {{
              sideViewerData.Address +
              " " +
              sideViewerData.City +
              " " +
              sideViewerData.State +
              " " +
              sideViewerData.Zip +
              " " +
              sideViewerData.Country || "NA" | titlecase
              }}
            </td>
          </tr>
        </table>
      </div>
    </mat-tab>

    <mat-tab label="Document Validation">
      <div class="mx-2 my-2">
        <div class="mx-2 my-2">
          <div class="border-bottom" *ngFor="let item of documentsList2">
            <div class="d-flex mb-1">
              <div class="py-2 d-flex flex-column">
                <img *ngIf="item.StudentDocPath != '-'" src="./assets/img/docs-r.svg" style="width: 16px" />
                <img *ngIf="item.StudentDocPath == '-'" src="./assets/img/doc-grey-2.svg" style="width: 16px" />
              </div>
              <div class="p-2 d-flex flex-column w200">
                <div class="sub-header-md mr-3 d-flex align-items-center">
                  <div style="line-height: 160%">
                    {{ item.ProgramDoc | titlecase }}
                  </div>
                  <button *ngIf="item.docType == 'customDoc'" style="background: none" (click)="
                      deleteCustomDocument(item.StudentdocId, item.ProgramDoc)
                    ">
                    <img class="img12" style="width: 10px" src="../../../assets/img/delete.svg" />
                  </button>
                </div>

                <div class="sub-date" *ngIf="item.uploadedDate != '-'">
                  {{ item.uploadedDate | date : "medium" }}
                </div>
              </div>
              <div>
                <div>
                  <span class="badge badge-pill badge-success mt-3"
                    *ngIf="item.AgentValidation == 'validated'">Validated</span>
                  <span class="badge badge-pill badge-warning mt-3" *ngIf="item.AgentValidation == 'revalidate'">Re
                    Validate</span>
                </div>

                <ng-template #validation11>
                  <form #form="ngForm">
                    <div style="width: 185px; font-size: 12px">
                      <div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                            value="validated" (change)="
                              changeValidation(
                                $event.target.value,
                                item.StudentdocId
                              )
                            " />
                          <label class="form-check-label" for="inlineRadio1">Validated</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                            value="revalidate" (change)="
                              changeValidation(
                                $event.target.value,
                                item.StudentdocId
                              )
                            " />
                          <label class="form-check-label" for="inlineRadio2">Re Validate</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </ng-template>

                <ng-template #comments11>
                  <form #form="ngForm">
                    <div class="d-flex align-items-end" style="width: 250px">
                      <div class="flex-grow-1">
                        <textarea class="form-control ct" type="text" #documentComment id="documentComments" rows="2"
                          placeholder="Write comments here..."></textarea>
                      </div>
                      <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0"
                        (click)="postComments(item, 'documentComments',sideViewerData)">
                        <img src="./assets/img/send-message.svg" style="width: 16px" />
                      </button>
                    </div>
                  </form>
                </ng-template>
              </div>
              <div class="ml-auto p-2">
                <button [disabled]="item.StudentDocPath == '-'" class="btn btn-light btn-sm mr-2" (click)="
                    downLoadDocument(item.StudentDocPath, item.ProgramDoc)
                  ">
                  <img src="../../../assets/img/file-download.svg" style="width: 16px" />
                </button>

                <input hidden type="file" #uploadFile (change)="fileUpload($event, item)" />

                <button [disabled]="sideViewerData.LeadDocStatus == 'completed'" data-toggle="modal"
                  data-target="#uploadFiles" placement="right" tooltipClass="my-custom-class"
                  class="btn btn-light btn-sm mr-2" (click)="clearSelectedFiles(item)">
                  <img src="./assets/img/file-upload.svg" style="width: 16px" />
                </button>
                <button type="button" class="btn btn-light btn-sm mr-2"
                  (click)="openPreview(pdfContent, item.StudentDocPath)" [disabled]="item.StudentDocPath == '-'">
                  <img src="./assets/img/view2.svg" style="width: 16px" />
                </button>
                <button type="button" class="btn btn-light btn-sm mr-2" placement="bottom" [ngbPopover]="validation11"
                  [disabled]="
                    item.StudentDocPath == '-' ||
                    sideViewerData.LeadDocStatus == 'completed'
                  ">
                  <img *ngIf="item.AgentValidation == '-'" src="./assets/img/tick-g.svg" style="width: 16px" />
                  <img *ngIf="item.AgentValidation == 'validated'" src="./assets/img/ticker-green.svg"
                    style="width: 16px" />
                  <img *ngIf="item.AgentValidation == 'revalidate'" src="./assets/img/ticker-red.svg"
                    style="width: 16px" />
                </button>
                <button type="button" class="btn btn-light btn-sm mr-2" placement="left" [ngbPopover]="comments11"
                  [disabled]="disabled" ngbTooltip="Add Comment">
                  <img src="./assets/img/comments.svg" style="width: 16px" />
                </button>
                <button type="button" data-toggle="modal" data-target="#exampleModal2" class="btn btn-light btn-sm mr-2"
                  (click)="callFromDocs(sideViewerData, item)">
                  <img src="./assets/img/calls-g.svg" style="width: 16px" />
                </button>
              </div>
            </div>

            <!--Comment section comes here-->
            <mat-expansion-panel *ngIf="item.DocComments.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title> Comments </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="comments-section" *ngFor="let i of item.DocComments">
                <div class="admin-comment" *ngIf="i.Admin == true">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i.ProfilePhoto != null" src="{{ i.ProfilePhoto }}" style="
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100px;
                          " />

                        <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                          {{ i.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3 flex-fill">
                          {{ i.AgentName }}
                        </div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i.DateTime | date : "medium" }}
                        </div>
                      </div>
                      <div class="sub-text flex-grow-1">{{ i.Text }}</div>
                    </div>
                  </div>
                </div>

                <div class="agent-comment" *ngIf="i.Admin == false">
                  <div class="d-flex">
                    <div>
                      <div *ngIf="i.AgentName" class="pic mr-1 float-left text-center">
                        <img *ngIf="i.ProfilePhoto != null" style="
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 100px;
                          " src="{{ i.ProfilePhoto }}" />

                        <p [ngStyle]="{ background: i.AgentColor }" class="initials" *ngIf="i.ProfilePhoto == null">
                          {{ i.AgentInitials }}
                        </p>
                      </div>
                    </div>
                    <div class="pl-2 pr-2 d-flex flex-column">
                      <div class="d-flex">
                        <div class="sub-header-sm mr-3">{{ i.AgentName }}</div>
                        <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                          {{ i.DateTime | date : "medium" }}
                        </div>
                      </div>
                      <div class="sub-text">{{ i.Text }}</div>
                    </div>
                  </div>
                </div>
                <div style="clear: both"></div>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="py-2">
            <button [disabled]="sideViewerData.LeadDocStatus == 'completed'" data-toggle="modal"
              data-target="#exampleModal4" ngbTooltip="Add New Document" placement="right"
              tooltipClass="my-custom-class" class="btn btn-primary btn-sm" (click)="clearData()"
              style="padding: 0 5px">
              <img src="./assets/img/add-w.svg" style="width: 10px" />
              Add
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="validationStatus == true" class="text-center mb-2">
        <button class="btn btn-success btn-sm mt-2" disabled style="width: 180px">
          <img src="./assets/img/green-tick-4.svg" style="width: 16px" />
          Validation Completed
        </button>
      </div>
      <div *ngIf="validationStatus == false" class="text-center mb-2">
        <button class="btn btn-danger btn-sm mt-2" disabled style="width: 180px">
          <img src="../assets/img/close-r.svg" style="width: 16px" /> Not
          Validated
        </button>
      </div>

      <div class="p-3">
        <!--Comments 2 section-->
        <div class="header-md">
          Comments
          <span style="font-size: 12px">{{
            "(" + (sideViewerData.LeadComments?.length || "0") + ")"
            }}</span>
        </div>
        <div class="comments-block" style="background: #fff">
          <div class="mb-3 pb-1 ct pr" style="background: #f5f5f5; border-radius: 5px">
            <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="leadComments" rows="2"
              placeholder="Write comments here..."></textarea>
            <button type="button" class="btn btn-primary btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
              (click)="postComments(sideViewerData, 'leadComments')">
              Submit
            </button>
            <div style="clear: both"></div>
          </div>
          <div class="m-h200">
            <div class="d-flex mb-2 mt-1" *ngFor="let j of sideViewerData.LeadComments">
              <div class="d-flex border-bottom pt-1" style="width: 100%">
                <div class="d-flex" style="width: 100%">
                  <div class="">
                    <div *ngIf="j.AgentName" class="pic mr-1 float-left text-center">
                      <img *ngIf="j.ProfilePhoto != null" style="
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                          border-radius: 100px;
                        " src="{{ j.ProfilePhoto }}" />

                      <p [ngStyle]="{ background: j.AgentColor }" class="initials" *ngIf="j.ProfilePhoto == null">
                        {{ j.AgentInitials }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="flex-grow-1">
                      <div class="sub-header-sm mr-3 mt-1 pb-1 flex-fill">
                        {{ j.AgentName }}
                      </div>
                    </div>
                    <div class="sub-text flex-grow-1 pb-2">
                      {{ j.Text }}
                    </div>
                  </div>
                  <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                    {{ j.DateTime | date : "medium" }}
                  </div>
                </div>

                <!-- <div>
                  <div class="img-circle2">
                    <img src="./assets/img/admin-comments.svg" style="width: 10px" />
                  </div>
                </div> -->
              </div>
              <!--
            <div>
              <div class="img-circle2 mr-2"><img src="./assets/img/admin-commentsx.svg" style="width:12px;" />
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="sub-header-md2 mb-1">
                {{j.Text}}
              </div>
              <div class="sub-date">{{j.DateTime | date:'medium'}}</div>
            </div>
          -->
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Communication" style="position: relative">
      <mat-tab-group class="sideDataViewer">
        <mat-tab label="SMS">
          <div xxstyle="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
            <div class="mb-3 pb-1 ct pr" style="
            background: #f5f5fa;
            border-radius: 10px;
            margin: 0 20px 0 10px;
          ">
              <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessage" rows="2"
                placeholder="Write your message here..."></textarea>
              <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
                (click)="sendSms(sideViewerData, 'textMessage')">
                <img src="../../../assets/img/send-message.svg" class="img12" />
              </button>
              <div style="clear: both"></div>
            </div>
          </div>
          <div class="p-3">
            <!--Comments 2 section-->
            <div class="header-md">
              Total Messages
              <span style="font-size: 12px">{{
                "(" + (smsList?.length || "0") + ")"
                }}</span>
            </div>
            <div class="comments-block" style="background: #fff">
              <div class="dyn-height">
                <div class="d-flex mb-0 mt-1" *ngFor="let j of smsList">
                  <div class="d-flex pt-1" style="width: 100%">
                    <div style="width: 100%">
                      <div class="admin-comment" style="
                      border: 1px solid #f5e7e7;
                      border-radius: 10px;
                      border-bottom-right-radius: 0;
                    " *ngIf="j.Direction == 'inbound'">
                        <div class="d-flex">
                          <div>
                            <div class="pic mr-1 float-left text-center">
                              <img src="../../../assets/img/student.svg" style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " />
                            </div>
                          </div>
                          <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                            <div class="d-flex">
                              <span class="header-12">Student</span>

                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ j.DateTime | date : "medium" }}
                              </div>
                            </div>
                            <div class="sub-text flex-grow-1">{{ j.Text }}</div>
                          </div>
                        </div>
                      </div>

                      <div class="agent-comment" style="
                      border-radius: 10px;
                      border-bottom-left-radius: 0;
                      border: 1px solid #def3f7;
                    " *ngIf="j.Direction == 'outbound'">
                        <div class="d-flex">
                          <div>
                            <div class="pic mr-1 float-left text-center">
                              <img style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " src="../../../assets/img/admin-comments.svg" />
                            </div>
                          </div>

                          <div class="pl-2 pr-2 d-flex flex-column">
                            <div class="d-flex">
                              <span class="header-12">Agent</span>
                              <!-- <div class="sub-header-sm mr-3">{{ i.AgentName }}</div> -->
                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ j.DateTime | date : "medium" }}
                              </div>
                            </div>
                            <div class="sub-text" style="width: 315px; line-height: 125%">
                              {{ j.Text }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="WhatsApp">
          <div style="position:absolute; left:15px;right:15px;bottom:0; height:100px;">
            <div class="mb-3 pb-1 ct pr" style="
            background: #f5f5fa;
            border-radius: 10px;
            margin: 0 20px 0 10px;
          ">
              <textarea class="form-control ct p-2 mb-1" style="background: #f5f5f5" id="textMessageWp" rows="2"
                placeholder="Write your message here...">Thank you for considering Campbellsville</textarea>
              <button type="button" class="btn btn-link btn-sm mr-2 mb-1 float-right" style="padding: 0 5px"
                (click)="sendSmsWp(sideViewerData, 'textMessageWp')">
                <img src="../../../assets/img/send-message.svg" class="img12" />
              </button>
              <div style="clear: both"></div>
            </div>
          </div>
          <div class="p-3">
            <!--Comments 2 section-->
            <div class="header-md">
              Total Messages
              <span style="font-size: 12px">{{
                "(" + (smsListWp?.length || "0") + ")"
                }}</span>
            </div>
            <div class="comments-block" style="background: #fff">
              <div class="dyn-height">
                <div class="d-flex mb-0 mt-1" *ngFor="let j of smsListWp">
                  <div class="d-flex pt-1" style="width: 100%">
                    <div style="width: 100%">
                      <div class="admin-comment" style="
                      border: 1px solid #f5e7e7;
                      border-radius: 10px;
                      border-bottom-right-radius: 0;
                    " *ngIf="j.Direction == 'inbound'">
                        <div class="d-flex">
                          <div>
                            <div class="pic mr-1 float-left text-center">
                              <img src="../../../assets/img/student.svg" style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " />
                            </div>
                          </div>
                          <div class="pl-2 pr-2 d-flex flex-column flex-grow-1">
                            <div class="d-flex">
                              <span class="header-12">Student</span>

                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ j.createdAt | date : "medium" }}
                              </div>
                            </div>
                            <div class="sub-text flex-grow-1">{{ j.Text }}</div>
                          </div>
                        </div>
                      </div>

                      <div class="agent-comment" style="
                      border-radius: 10px;
                      border-bottom-left-radius: 0;
                      border: 1px solid #def3f7;
                    " *ngIf="j.Direction == 'outbound'">
                        <div class="d-flex">
                          <div>
                            <div class="pic mr-1 float-left text-center">
                              <img style="
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 100px;
                            " src="../../../assets/img/admin-comments.svg" />
                            </div>
                          </div>

                          <div class="pl-2 pr-2 d-flex flex-column">
                            <div class="d-flex" style="margin-right: 20px;">
                              <span class="header-12">Agent</span>
                              <!-- <div class="sub-header-sm mr-3">{{ i.AgentName }}</div> -->
                              <div class="sub-date float-right ml-auto" style="white-space: nowrap">
                                {{ j.createdAt | date : "medium" }}
                              </div>
                            </div>
                            <div class="sub-text" style="width: 315px; line-height: 125%">
                              {{ j.Text }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>

    <mat-tab label="Activity">
      <div class="mx-2 my-2">
        <div class="alert alert-info mb-0 d-flex">
          <div class="" style="line-height: 200%">
            {{ sideViewerData.Activities.length }} Actions taken
          </div>
          <div class="ml-auto" style="width: 180px">
            <input class="searchActivity" type="text" style="font-size: 13px" class="form-control search-input"
              placeholder="Search" [(ngModel)]="activityfilterString"
              (input)="onInputActivity(sideViewerData?.Activities?.length)" />
          </div>
        </div>
        <div *ngFor="
            let i of sideViewerData.Activities
              | slice : 0 : minItems
              | searchFilterTable : activityfilterString
          ">
          <div class="myactivity d-flex align-items-start">
            <img class="img16 mr-2" src="./assets/img/tick-green.svg" />
            <p>
              {{ i.Description }}
              <span class="sm-text ml-2">{{
                i.DateTime | date : "medium"
                }}</span>
            </p>
          </div>
          <hr style="margin: 0; padding: 0" />
        </div>
        <div class="loadmore hand" *ngIf="minItems < sideViewerData.Activities.length"
          (click)="showmore(sideViewerData.Activities.length)">
          Show more..
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-sidenav>

<div class="container-fluid">
  <div class="pt-3 pl-2 pr-2 d-flex justify-content-between mb-4">
    <div class="db-sm-header" style="line-height: 330%">Activity</div>
    <div class="db-sm-header mb-1 ml-auto" (click)="hideDate()">
      <button class="form-group" style="padding: 0">
        <select class="form-control cdd" id="" style="padding: 0; background: #fff" [(ngModel)]="filterValue"
          (change)="updateDateFilter($event)">
          <option value="all">All</option>
          <option value="24hrs">24 hrs</option>
          <option value="72hrs">72 hrs</option>
          <option value="7days">07 days</option>
          <option value="14days">14 days</option>
          <option value="30days">30 days</option>
          <option value="custom">Custom</option>
        </select>
      </button>
    </div>

    <div *ngIf="showDate" class="datePicker">
      <div style="background: #fff; height: 76px; border: 1px solid #ccc" class="custom-date">
        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker1" placeholder="Start Date" [formControl]="date2"
            (dateInput)="getStartDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 style="width: 80px"></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="mr-3">
          <input class="label" matInput [matDatepicker]="picker2" placeholder="End Date" [formControl]="date1"
            (dateInput)="getEndDate('input', $event)" />
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 style="width: 80px"></mat-datepicker>
        </mat-form-field>
        <button class="btn btn-secondary btn-sm" (click)="filterData()">
          Submit
        </button>
      </div>
    </div>
  </div>

  <!-- Carousel  -->

  <carousel [cellsToScroll]="5" style="padding-left: 30px; z-index: unset" class="fixheight">
    <div class="carousel-cell">
      <div class="mr-3" #totalRecords (click)="showTotalApplications()">
        <div class="db-block w-175" (click)="onClick(1)" [ngClass]="{ 'db-block-active': tab === 'tab1' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ totalApplications }} -->
                  {{ topBarCounts?.total }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Total Students</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showAssignedtoMe()">
        <div class="db-block w-175" (click)="onClick(13)" [ngClass]="{ 'db-block-active': tab === 'tab13' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 26px; margin-left: 3px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ assignedtome }} -->
                  {{ topBarCounts?.assignedtoMe || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Assigned to me</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showCompletedApplications2()">
        <div class="db-block w-175" (click)="onClick(3)" [ngClass]="{ 'db-block-active': tab === 'tab3' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/completed_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ completedApplications2 }} -->
                  {{ topBarCounts?.completedDocs || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Completed Documents</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showCompletedApplications()">
        <div class="db-block w-175" (click)="onClick(2)" [ngClass]="{ 'db-block-active': tab === 'tab2' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/completed_applications.svg" style="width: 20px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ completedApplications }} -->
                  {{ topBarCounts?.validatedDocs || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Validated Documents</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showDocumentsSubmitted()">
        <div class="db-block w-175" (click)="onClick(5)" [ngClass]="{ 'db-block-active': tab === 'tab5' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/documents_submitting.svg" style="width: 26px; margin-left: 3px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ documentssubmitted }} -->
                  {{ topBarCounts?.documentsSubmitted || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Documents Submitted</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showDocumentsPending()">
        <div class="db-block w-175" (click)="onClick(4)" [ngClass]="{ 'db-block-active': tab === 'tab4' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/documents_pending.svg" style="width: 26px; margin-left: 3px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ documentspending }} -->
                  {{ topBarCounts?.pendingDocs || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Documents Pending</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showApplicationsDenied()">
        <div class="db-block w-175" (click)="onClick(7)" [ngClass]="{ 'db-block-active': tab === 'tab7' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/applications-denied.svg" style="width: 26px; margin-left: 3px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ documentsDenied }} -->
                  {{ topBarCounts?.studentDenied || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Students Denied</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showRevalidate()">
        <div class="db-block w-175" (click)="onClick(9)" [ngClass]="{ 'db-block-active': tab === 'tab9' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/revalidate-docs.svg" style="width: 26px; margin-left: 3px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ revalidate }} -->
                  {{ topBarCounts?.revalidatedDocs || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Revalidate Documents</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showUncontactable()">
        <div class="db-block w-175" (click)="onClick(10)" [ngClass]="{ 'db-block-active': tab === 'tab10' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/uncontactable-3.svg" style="width: 26px; margin-left: 3px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ revalidate }} -->
                  {{ topBarCounts?.uncontactable || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Uncontactable</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showUnassigned()">
        <div class="db-block w-175" (click)="onClick(11)" [ngClass]="{ 'db-block-active': tab === 'tab11' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 26px; margin-left: 3px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ unassigned }} -->
                  {{ topBarCounts?.unassigned || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Unassigned</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showAssigned()">
        <div class="db-block w-175" (click)="onClick(12)" [ngClass]="{ 'db-block-active': tab === 'tab12' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 26px; margin-left: 3px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ assigned }} -->
                  {{ topBarCounts?.assigned || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Assigned</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell">
      <div class="mr-3" (click)="showPreviousIntake()">
        <div class="db-block w-175" (click)="onClick(14)" [ngClass]="{ 'db-block-active': tab === 'tab14' }">
          <div class="p-3">
            <div class="d-flex">
              <div class="img-circle d-flex">
                <img src="../../../../assets/img/total_applications.svg" style="width: 26px; margin-left: 3px" />
              </div>
              <div class="f25 text-right ml-auto mt-3">
                <div *ngIf="!isTopBarBind">
                  <img src="../../../../assets/img/waiting.gif" class="gif-loader" />
                </div>
                <div *ngIf="isTopBarBind">
                  <!-- {{ assigned }} -->
                  {{ topBarCounts?.previousintake || 0 }}
                </div>
              </div>
            </div>
            <div class="box-sub-text1 mt-2">Previous Intake</div>
          </div>
        </div>
      </div>
    </div>

    <div class="carousel-cell"></div>
  </carousel>

  <!-- End Carousel -->

  <!-- Activity -->

  <!-- End Carousel -->

  <!-- Activity -->
  <div>
    <div>
      <div>
        <div class="d-flex align-items-end">
          <div class="ml-3 header-12">Total Applications</div>
          <div class="ml-auto mr-3">
            <div class="form-control form-control-dark" style="
                height: 0;
                padding: 0px;
                background: none;
                font-size: 13px;
                color: #999;
                border: 0;
              "></div>
            <!-- <div *ngIf="uploaded">
          {{ insertCount + " " + "Contacts inserted" }}
        </div> -->
            <div class="upload-msg-success" style="color: green; font-size: 10px"></div>
            <div class="upload-msg-error"></div>

            <!-- attachment bar hidden -->
            <div class="content-containers" style="margin-left: 30px">
              <input type="file" (change)="convertFile($event)" style="display: none" #uploadFile accept=".xlsx" />

              <div class="d-flex justify-content-end mt-3">
                <div>
                  <div class="float-left mr-3">
                    <div *ngIf="filePath" class="form-control" style="
                        height: 16px;
                        padding: 0px;
                        background: none;
                        font-size: 13px;
                        color: #999;
                        border: 0;
                      ">
                      {{ filePath }}
                    </div>
                    <div class="upload-msg-success" *ngIf="uploaded" style="color: green; font-size: 10px">
                      {{ insertCount + " " + "Contacts inserted" }}
                    </div>
                    <div class="upload-msg-error" *ngIf="error">
                      {{ errorMessage }}
                    </div>
                  </div>

                  <div style="clear: both"></div>
                </div>

                <!-- for add new document screen-->
                <div class="modal fade" id="exampleModal4" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 440px">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Add New Document
                        </h5>
                        <button type="button" #closeButton class="close" data-dismiss="modal" aria-label="Close"
                          (click)="customFileList = [];docUploadError='';">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="d-flex">
                          <div class="form-group mr-2">
                            <label for="exampleInputEmail1" style="font-size: 13px">Document Name</label>
                            <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="customDocName"
                              (change)="customDocNameChange($event.target.value)">
                              <option value="" disabled selected>
                                Select Document
                              </option>
                              <option *ngFor="let item of customDocList" [value]="item.name" [disabled]="item.disabled">
                                {{item?.name}}</option>
                            </select>
                            <div class="mt-2">
                              <small style="font-size: 14px;"><b>Please use CTRL to select multiple files .</b></small>
                            </div>
                          </div>
                          <div>
                            <input hidden type="file" #uploadFile2 (change)="uploadCustomDocument($event)" multiple />
                            <button type="button" class="btn btn-secondary btn-sm mr-2" style="
                                padding: 5px 10px;
                                margin-top: 22px;
                                font-size: 19px;
                              " (click)="uploadFile2.click()">
                              <img class="img12" src="../../../assets/img/upload-w.svg" />
                            </button>
                          </div>
                          <div>
                            <button (click)="addCustomDocument()" type="button" class="btn btn-secondary btn-sm" style="
                                padding: 8px 10px;
                                margin-top: 22px;
                                font-size: 13px;
                              ">
                              Add
                            </button>
                          </div>
                        </div>
                        <div>
                          <div class="add-docs-item-sub ml-auto">
                            <div class="txt-oflow-lg text-left">
                              Selected Files: {{ customFileList.length }}
                            </div>
                          </div>
                          <div *ngFor="let i of customFileList" class="d-flex add-docs-item align-items-center">
                            <div class="">
                              <img class="img12 mr-1 lh160" src="../../../assets/img/docs-r.svg" />
                              {{ i.name | titlecase }}<br />
                              <div class="add-docs-item-sub d-flex" style="margin-left: 20px">
                                <div class="txt-oflow-lg">
                                  {{ i.fileName }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="customFileList.length == 0" class="d-flex p-3 flex-column">
                          <div class="text-center p-3">
                            <img src="../../../assets/img/upload-file-2.png" />
                          </div>
                          <div class="text-center">
                            Upload below supported documents only <br />
                            <span style="font-size: 18px; line-height: 180%">.doc .pdf .png .jpg .jpeg</span>
                          </div>
                        </div>
                        <div>
                          <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
                        </div>
                        <div class="p-3 text-center docUploadError" style="
                            color: green;
                            font-size: 14px;
                            font-weight: bold;
                          ">
                          {{ docUploadError }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- for upload multiple files-->
                <div class="modal fade" id="uploadFiles" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content" style="width: 400px">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Upload Documents
                        </h5>
                        <button type="button" id="closeButton4" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div *ngIf="uploadSuccess == 'none'">
                          <div class="d-flex" *ngIf="!docUploadStatus">
                            <div>
                              <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                                Select Files
                              </button>
                              <input type="file" #filesInput id="filesInput" multiple (change)="getFileDetails($event)"
                                style="display: none" />
                            </div>
                            <div class="add-docs-item-sub ml-auto">
                              <div class="txt-oflow-lg text-right">
                                Selected Files: {{ selectedFiles.length }}
                              </div>
                            </div>
                          </div>

                          <!--Addon-->
                          <div *ngIf="selectedFiles.length == 0" class="d-flex p-3 flex-column">
                            <div class="text-center p-3">
                              <img src="../../../assets/img/upload-file-2.png" />
                            </div>
                            <div class="text-center">
                              Upload below supported documents only <br />
                              <span style="font-size: 18px; line-height: 180%">.doc .pdf .png .jpg .jpeg</span>
                            </div>
                          </div>
                          <!--End-->

                          <!-- <button type="button" (click)="filesInput.click()">trigger</button> -->
                          <div *ngIf="selectedFiles.length > 0">
                            <div *ngIf="!docUploadStatus" class="py-2 mb-3" style="height: 160px; overflow: auto">
                              <div *ngFor="
                                  let i of selectedFiles;
                                  let indexOfelement = index
                                " class="d-flex add-docs-item align-items-center py-2">
                                <div class="">
                                  <img class="img12 mr-1 lh160 float-left" src="../../../assets/img/docs-r.svg" />
                                  <div class="txt-oflow-lg" style="
                                      display: inline-block;
                                      line-height: 100%;
                                    ">
                                    {{ i.name }}
                                  </div>
                                  <br />
                                  <!-- <div class="add-docs-item-sub d-flex" style="margin-left: 20px">
                          <div class="txt-oflow-lg">
                            {{i.fileName}}
                          </div>
                        </div> -->
                                </div>
                                <div class="ml-auto">
                                  <button style="background: none" (click)="deleteFile(indexOfelement)">
                                    <img class="img12" src="../../../assets/img/delete.svg" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div *ngIf="!docUploadStatus">
                            <div class="d-flex">
                              <div *ngIf="selectedFiles.length > 0">
                                <div *ngIf="!docUploadStatus">
                                  <button (click)="filesInput.click()" type="button" class="btn btn-secondary btn-sm">
                                    + Add more
                                  </button>

                                  <input type="file" #filesInput id="filesInput" multiple
                                    (change)="getFileDetails($event)" style="display: none" />
                                </div>
                              </div>
                              <div class="ml-auto">
                                <button *ngIf="selectedFiles.length > 0" #uploadFilesButton type="button"
                                  class="btn btn-primary btn-sm mr-2" (click)="uploadAttachments()">
                                  Upload
                                </button>
                                <!-- <input type="file" #filesInput id="filesInput" multiple (change)="getFileDetails($event)"
                            style="display: none;"> -->
                                <button (click)="cancelUpload()" type="button" class="btn btn-light btn-sm">
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="p-3 header-sm" style="text-align: center;line-height: 140%;color: red;">
                        {{docUploadError2}}
                      </div> -->
                        <div *ngIf="!docUploadStatus">
                          <!--Addon-->
                          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess == 'success'">
                            <div class="text-center p-3">
                              <img src="../../../assets/img/upload-success.png" />
                            </div>
                            <div class="text-center">
                              <b>Upload Success</b><br />
                              <div style="
                                  font-size: 14px;
                                  line-height: 130%;
                                  padding: 5px;
                                ">
                                Total {{ selectedFiles.length }} files uploaded
                                successfully
                              </div>
                            </div>
                          </div>
                          <!--End-->

                          <!--Addon-->
                          <div class="d-flex p-3 flex-column" *ngIf="uploadSuccess == 'failure'">
                            <div class="text-center p-3">
                              <img src="../../../assets/img/upload-failed.png" />
                            </div>
                            <div class="text-center">
                              <b>Upload Failed</b><br />
                              <div style="
                                  font-size: 14px;
                                  line-height: 130%;
                                  padding: 5px;
                                ">
                                Files not uploaded. Please check document
                                extensions, upload supported documents
                              </div>
                            </div>
                          </div>
                          <!--End-->
                        </div>
                        <div>
                          <mat-progress-bar mode="indeterminate" *ngIf="docUploadStatus"></mat-progress-bar>
                        </div>
                        <div class="p-3 text-center docUploadError" *ngIf="uploadSuccess == 'none'" style="
                            color: green;
                            font-size: 14px;
                            font-weight: bold;
                          ">
                          {{ docUploadError2 }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- for bulk status update model-->
                <div class="modal fade" id="exampleModalBulk" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Status Change
                        </h5>
                        <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal"
                          aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="height: none">
                        <div class="px-2">
                          <div class="py-2 header-lg">
                            <b>{{ leadIds.length }}</b> Students are selected for
                            update.
                          </div>
                          <div class="d-flex mt-2 mb-3">
                            <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
                              <select class="form-control" id="exampleFormControlSelect1"
                                (change)="bulkStatusSelected($event.target.value)">
                                <option value="default" disabled selected>
                                  Select Status
                                </option>
                                <option value="previousintake"> Previous Intake</option>
                              </select>
                            </div>
                            <button type="button" class="btn btn-secondary btn-sm" (click)="bulkLeadsChange()">
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- attachment bar hidden ends -->
                <button data-toggle="modal" data-target="#exampleModalBulk" *ngIf="admin"
                  class="btn btn-secondary btn-sm ml-2" style="color: #fff" ngbTooltip="Status Change" placement="top"
                  tooltipClass="my-custom-class">
                  <img src="../../../../assets/images/pen.svg" class="img13" />
                </button>

                <!-- for assign model-->
                <div class="modal fade" id="exampleModal3" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Assign to Agent
                        </h5>
                        <button type="button" id="closeAssignButton" #closeButton class="close" data-dismiss="modal"
                          aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="height: none">
                        <div class="px-2">
                          <div class="py-2 header-lg">
                            <b>{{ leadIds.length }}</b> Students are selected to
                            assign.
                          </div>
                          <div class="d-flex mt-2 mb-3">
                            <div class="form-group mr-2" style="margin-bottom: 0; width: 220px">
                              <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="defaultAgent"
                                (change)="agentSelected($event.target.value)">
                                <option value="default" disabled selected>
                                  Select Agent
                                </option>
                                <option *ngFor="let agent of agentList" [value]="agent.Mailid">
                                  {{ agent.FirstName + " " + agent.LastName }}
                                </option>
                              </select>
                            </div>
                            <button type="button" class="btn btn-secondary btn-sm" (click)="assignLEads()">
                              Assign
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- attachment bar hidden ends -->
                <button data-toggle="modal" data-target="#exampleModal3" *ngIf="admin" (click)="assignAgentModal()"
                  class="btn btn-secondary btn-sm ml-2" style="color: #fff" ngbTooltip="Assign" placement="top"
                  tooltipClass="my-custom-class">
                  <img src="../../../../assets/img/assign-w.svg" class="img13" />
                </button>

                <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Download Sample" placement="top"
                  tooltipClass="my-custom-class">
                  <a href="{{ downloadLink }}">
                    <img src="../../../../assets/img/download-w.svg" class="img13" /></a>
                </button>

                <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Upload File" placement="top"
                  (click)="redirectToUpload()" tooltipClass="my-custom-class">
                  <img src="../../../../assets/img/upload-w.svg" class="img13" />
                </button>

                <button class="btn btn-secondary btn-sm ml-2" ngbTooltip="Add New" placement="top"
                  (click)="openCreateLead(content3)" tooltipClass="my-custom-class">
                  <img src="../../../../assets/img/add-w.svg" class="img13" />
                </button>
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <div>
          <div class="mt-1 ml-3 mr-3">
            <div class="row">
              <div class="pl-2 pr-2 pt-2 mb-3" style="min-height: 300px; width: 100%">
                <div class="table-responsive data-table mb-3">
                  <table class="table table-sm wbg table-shadow" style="margin-bottom: 0px; width: 100%">
                    <thead>
                      <tr>
                        <td colspan="5">
                          <div class="indived-search" style="position: relative">

                            <input type="search" (input)="onInput($event)" #inputString class="form-control search-all"
                              style="padding: 4px 12px" [(ngModel)]="search" placeholder="Search " autocomplete="off" />
                          </div>
                        </td>
                        <td colspan="10" class="vm">
                          <div class="pagenation-right d-flex justify-content-end align-items-center">
                            <select class="form-select form-select-dark mx-2" [(ngModel)]="statusValue"
                              (ngModelChange)="dropdownSelect($event)">
                              <option value="all">All</option>
                              <option value="pending">Pending</option>
                              <option value="called">Called</option>
                              <option value="callback">Call Back</option>
                              <option value="informed">Informed</option>
                              <option value="callfailed">Wrong Number</option>
                              <option value="notanswered">Not Answered</option>
                              <option value="deferredintake">
                                Deferred Intake
                              </option>
                              <option value="uncontactable">
                                Un Contactable
                              </option>
                            </select>
                            <div class="float-right" style="color: #fff; font-size: 12px">
                              <app-custom-pagination></app-custom-pagination>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tr>
                      <th style="width: 30px; white-space: nowrap;padding-left: 20px;" *ngIf="admin">
                        <div style="width: 20px">
                          <input type="checkbox" [checked]="selectedAll == true" class="selectAll" id="selectAll"
                            (change)="selectAllforAssignment()" />
                        </div>
                      </th>
                      <th nowrap>Actions</th>
                      <th nowrap>
                        <div class="d-flex">
                          <div *ngIf=" currentCard == 'total' ||
                          currentCard == 'updated' || 
                          currentCard === 'previousintake'">Created</div>
                          <div *ngIf="currentCard == 'validated'">
                            Validation
                          </div>
                          <div *ngIf="currentCard == 'uncontactable'">
                            Uncontactable
                          </div>
                          <div *ngIf="currentCard == 'completed'">
                            Completed
                          </div>
                          <div *ngIf="currentCard == 'revalidate'">
                            Revalidation
                          </div>
                          <div *ngIf="currentCard == 'denied'">Denied</div>
                          <div *ngIf="currentCard == 'assigned'">
                            Assigned
                          </div>
                          <span><button class="sortButton" (click)="toggleSort('createdAt', 'asc')">
                              <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                        </div>
                      </th>
                      <th nowrap>
                        Updated
                        <span><button class="sortButton" (click)="toggleSort('updatedAt', 'asc')">
                            <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Name
                        <span><button class="sortButton" (click)="toggleSort('FirstName', 'asc')">
                            <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Email
                        <span><button class="sortButton" (click)="toggleSort('Email', 'asc')">
                            <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Assigned To
                        <span><button class="sortButton" (click)="toggleSort('AssignedtoName', 'asc')">
                            <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Phone
                        <span><button class="sortButton" (click)="toggleSort('PhoneNumber', 'asc')">
                            <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Term
                        <span><button class="sortButton" (click)="toggleSort('City', 'asc')">
                            <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Programme
                        <span><button class="sortButton" (click)="toggleSort('Program', 'asc')">
                            <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Doc_Status
                        <span><button class="sortButton" (click)="toggleSort('LeadDocStatus', 'asc')">
                            <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                      <th nowrap>
                        Call_Status
                        <span><button class="sortButton" (click)="toggleSort('CallStatus', 'asc')">
                            <img class="img7 ml-1" src="../../../../assets/img/sort-data.svg" /></button></span>
                      </th>
                    </tr>

                    <!-- <tbody> -->
                    <tr *ngFor="let item of activityTable; let id = index">
                      <td class="vm" style="width: 30px; white-space: nowrap;padding-left: 20px;" *ngIf="admin">
                        <div style="width: 20px">
                          <input type="checkbox" name="lead" [checked]="selectedAll == true" class="selectLead"
                            id="selectLead" (change)="
                                  selectAgentforAssignment($event, item)
                                " />
                        </div>
                      </td>
                      <td class="text-capitalize vm">
                        <div class="example-button-row mat-bset d-flex">
                          <mat-sidenav-container ngbTooltip="Overview" placement="top" tooltipClass="my-custom-class">
                            <mat-sidenav-content>
                              <button class="viewLeadButton" (click)="sideNav.toggle(); sideNavData(item)" mat-button>
                                <img class="img12" src="../../../../assets/img/view2.svg" />
                              </button>
                            </mat-sidenav-content>
                          </mat-sidenav-container>
                          <button mat-button (click)="checkThresHold(totalCallCount, item, id)" ngbTooltip="Call"
                            placement="top" tooltipClass="my-custom-class">
                            <img class="img12" src="../../../../assets/img/call.svg" />
                          </button>
                          <button mat-button id="openModalButton" [hidden]="true" ngbTooltip="Call" placement="top"
                            tooltipClass="my-custom-class" data-toggle="modal" data-target="#staticBackdrop">
                            <img class="img12" src="../../../../assets/img/call.svg" />
                          </button>
                        </div>
                      </td>
                      <td class="vm" nowrap>
                        <div class="d-flex">
                          <div style="line-height: 125%">
                            <span *ngIf="
                                  currentCard == 'total' ||
                                  currentCard == 'updated' || 
                                  currentCard === 'previousintake'
                                ">{{
                              item?.createdAt | date : "dd MMM, yyyy"
                              }}</span>
                            <span *ngIf="currentCard == 'validated'">{{
                              item?.ValidatedDate | date : "dd MMM, yyyy"
                              }}</span>
                            <span *ngIf="currentCard == 'uncontactable'">{{
                              item?.createdAt | date : "dd MMM, yyyy"
                              }}</span>
                            <span *ngIf="currentCard == 'completed'">{{
                              item?.CompletedDate | date : "dd MMM, yyyy"
                              }}</span>
                            <span *ngIf="currentCard == 'denied'">{{
                              item?.DeniedDate | date : "dd MMM, yyyy"
                              }}</span>
                            <span *ngIf="currentCard == 'revalidate'">{{
                              item?.RevalidateDate | date : "dd MMM, yyyy"
                              }}</span>
                            <span *ngIf="currentCard == 'assigned'">{{
                              item?.createdAt | date : "dd MMM, yyyy"
                              }}</span>
                          </div>
                        </div>
                      </td>
                      <td class="vm">
                        <div class="tooltip_title">
                          <div class="txt-oflow2">
                            <span>
                              {{ item.updatedAt | date : "dd MMM, yyyy" }}
                            </span>
                          </div>
                          <span class="tooltiptext">{{ item.updatedAt | date : "dd MMM, yyyy" }}</span>
                        </div>
                        <!-- <div>
                            {{ item.updatedAt | date : "dd MMM, yyyy" }}
                          </div> -->
                      </td>
                      <td class="vm" nowrap>
                        <button class="btn btn-link btn-sm" style="position: relative"
                          (click)="openEditLead(editLead2, item)">
                          <div class="tooltip_title">
                            <div class="txt-oflow2">
                              <span>
                                {{
                                item.FirstName + " " + item.LastName | titlecase
                                }}
                              </span>
                            </div>
                            <span class="tooltiptext">{{item.FirstName + " " + item.LastName | titlecase }}</span>
                          </div>
                        </button>
                        <!-- <div class="txt-oflow">
                            <button class="btn btn-link btn-sm" style="position: relative"
                              (click)="openEditLead(editLead2, item)">
                              {{
                              item.FirstName + " " + item.LastName | titlecase
                              }}
                            </button>
                          </div> -->
                      </td>
                      <td class="vm">
                        <div class="tooltip_title">
                          <div class="txt-oflow2">
                            <span>
                              {{ item.Email | lowercase }}
                            </span>
                          </div>
                          <span class="tooltiptext">{{ item.Email | lowercase }}</span>
                        </div>
                        <!-- <div class="txt-oflow">
                            {{ item.Email | lowercase }}
                          </div> -->
                      </td>
                      <td class="vm" nowrap>
                        <div class="d-flex">
                          <div *ngIf="item.AssignedtoName" class="pic mr-1 float-left text-center">
                            <img *ngIf="item.AssignedtoImageURL != null" class="img20"
                              src="{{ item.AssignedtoImageURL }}" />

                            <p [ngStyle]="{ background: item.AssignedtoColor }" class="initials"
                              *ngIf="item.AssignedtoImageURL == null">
                              {{ item.AssignedtoInitial }}
                            </p>
                          </div>
                          <div class="tooltip_title">
                            <div class="txt-oflow2">
                              <span>
                                {{ item.AssignedtoName | titlecase }}
                              </span>
                            </div>
                            <span class="tooltiptext">{{ item.AssignedtoName | titlecase }}</span>
                          </div>
                          <!-- <div class="txt-oflow2 float-left">
                              {{ item.AssignedtoName | titlecase }}
                            </div> -->
                        </div>
                      </td>
                      <td class="text-capitalize vm">
                        {{ item.PhoneNumber }}
                      </td>
                      <td class="vm">
                        {{ item.EnrollmentTerm }}
                      </td>
                      <td class="text-capitalize vm">
                        {{ item.Program | uppercase }}
                      </td>

                      <td class="text-capitalize vm">
                        <div class="tooltip_title" *ngIf="item?.LeadDocStatus == 'notsubmitted'">
                          <div class="txt-oflow2">
                            <span>
                              Not Submitted
                            </span>
                          </div>
                          <span class="tooltiptext">Not Submitted</span>
                        </div>
                        <div class="tooltip_title" *ngIf="item?.LeadDocStatus != 'notsubmitted'">
                          <div class="txt-oflow2">
                            <span>
                              {{ getLeadDocStatusName(item?.LeadDocStatus) | titlecase }}
                            </span>
                          </div>
                          <span class="tooltiptext"> {{ getLeadDocStatusName(item?.LeadDocStatus) | titlecase }}</span>
                        </div>
                        <!-- <span >
                            Not Submitted
                          </span>
                          <span *ngIf="item?.LeadDocStatus != 'notsubmitted'">
                            {{ getLeadDocStatusName(item?.LeadDocStatus) | titlecase }}
                          </span> -->
                      </td>
                      <td class="text-capitalize vm">
                        <span class="status-pending" *ngIf="item.CallStatus == 'pending'">Pending</span>
                        <span class="status-pending" *ngIf="item.CallStatus == 'notinterested'">Not Interested</span>
                        <span class="status-completed" *ngIf="item.CallStatus == 'completed'">Completed</span>
                        <span class="status-callback" *ngIf="item.CallStatus == 'callback'">Call Back</span>
                        <span class="status-informed" *ngIf="item.CallStatus == 'informed'">Informed</span>
                        <span class="status-informed" *ngIf="item.CallStatus == 'called'">Called</span>
                        <span class="status-failed" *ngIf="item.CallStatus == 'callfailed'">Wrong Number</span>
                        <span class="status-deferred" *ngIf="item.CallStatus == 'deferredintake'">Deferred
                          Intake</span>
                        <span class="status-pending" *ngIf="
                              item.CallStatus == 'callnotanswered' ||
                              item.CallStatus == 'notanswered'
                            ">Not Answered</span>
                        <span class="status-deferred" *ngIf="
                              item.CallStatus == 'deferred' ||
                              item.CallStatus == 'Deferred'
                            ">Deferred Intake</span>
                        <span class="status-uncontactable" *ngIf="
                              item.CallStatus == 'uncontactable' ||
                              item.CallStatus == 'uncontactable'
                            ">Un Contactable</span>
                      </td>
                    </tr>
                    <tr class="h30"></tr>
                    <tr *ngIf="isLoading == true">
                      <td colspan="10">
                        <div class="norecords">
                          <img src="../../../../assets/img/waiting.gif" />

                          <div>
                            Loading Data<br />Please wait while we fetch the
                            data for you
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="noRecords == true">
                      <td colspan="10">
                        <div class="norecords">
                          <img src="../../../../assets/img/nodata.svg" />
                          <div>
                            No records found.<br />Please select a different
                            date range.
                          </div>
                        </div>
                      </td>
                    </tr>
                    <!-- </tbody> -->
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- for Modal -->
        <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg custom-model-w">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Calling Screens
                </h5>

                <button type="button" #closeButton2 class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="overflow-y: auto">
                <div class="row">
                  <div class="call-module ml-3">
                    <div class="ndialer">
                      <!--.......code for no- incoming call screen.........  -->
                      <div class="c-caller">Current Caller</div>
                      <div class="dialer-middle-screen" style="text-align: center"
                        *ngIf="dialerScreen1 === 'outgoing1'">
                        <div class="custom-incomingcall-widget">
                          <h4 class="mt-3 text-ellipsis">
                            {{ marketingId.name }}
                          </h4>
                          <div class="mb-2 mt-1 lg-text" style="font-weight: 100" *ngIf="marketingId.city">
                            {{ marketingId.city }}
                          </div>
                          <div class="my-1 lg-text">
                            {{ marketingId.phoneNumber || agentCall }}
                          </div>
                          <div class="custom-user-img my-3 mt-5">
                            <img class="img80" src="./assets/img/user-w.svg" alt="" />
                          </div>
                          <h5 *ngIf="callStatus != 'inProgress'">
                            {{ callStatus }}
                          </h5>
                          <p *ngIf="callStatus == 'inProgress'" class="custom-call-timer" style="text-align: center">
                            <countup-timer [countUpTimerConfig]="testConfig"></countup-timer>
                          </p>

                          <div class="custom-incoming-btns my-3 mt-4">
                            <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                              <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mt-2 p-3 ndialer2 ng-star-inserted" *ngIf="marketingId.nextCaller">
                      <div class="mt-4" style="float: right">
                        <img class="img50" src="./assets/img/user-w.svg" alt="" />
                      </div>
                      <div class="sm-text" style="
                          text-transform: uppercase;
                          font-weight: 100;
                          background: #f75c2b;
                          width: 110px;
                          padding: 5px;
                          border-radius: 15px;
                          letter-spacing: 1px;
                          text-align: center;
                          color: #fff;
                        ">
                        NEXT CALLER
                      </div>
                      <div class="lg-text pt-2 pb-1">
                        {{ nextCallerName }}
                      </div>
                      <div class="md-text pb-2">{{ nextCallerCity }}</div>
                      <div class="md-text">
                        {{ nextCallerNumber }}
                      </div>
                    </div>

                    <div class="mt-2 d-flex justify-content-between">
                      <div class="cbtn" type="button" placement="top" tooltipClass="my-custom-class"
                        (click)="startCallListener()">
                        <img class="img18" src="./assets/img/redial3.svg" alt="" />
                      </div>

                      <button class="cbtn" type="button" placement="right" [ngbPopover]="calendarContent"
                        [disabled]="disabled">
                        <!-- tooltipClass="my-custom-class" -->
                        <img class="img18" src="./assets/img/conference.svg" alt="" />
                      </button>
                      <ng-template #calendarContent>
                        <form (ngSubmit)="conferenceCall(form.value)" #form="ngForm">
                          <div class="xp-3" style="width: 185px">
                            <div class="mb-1">
                              <input id="phone_number" name="phone_number" type="text" class="form-control"
                                placeholder="Enter Phone Number" ngModel />
                            </div>
                            <ng-multiselect-dropdown name="manager" [data]="layoutService.projectAgents"
                              [(ngModel)]="layoutService.projectSelectedAgent"
                              [settings]="layoutService.dropdownSettings" style="width: 100%; padding: 0"
                              class="dropdown-primary"></ng-multiselect-dropdown>
                            <button class="btn btn-primary btn-block btn-md mt-2">
                              Connect
                            </button>
                          </div>
                        </form>
                      </ng-template>

                      <div class="cbtn" type="button" ngbTooltip="Stop Calls" placement="top"
                        tooltipClass="my-custom-class" (click)="stopCurrentCall()">
                        <img class="img18" style="padding-top: 3px" src="./assets/img/stop-call-r.svg" alt="" />
                      </div>

                      <div class="cbtn" type="button" ngbTooltip="Start Calls" placement="top"
                        tooltipClass="my-custom-class" (click)="callNext()">
                        <img class="img18" style="padding-top: 3px" src="./assets/img/start-calls.svg" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="call-module-update ml-4 mr-2">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="header-md mt-2 mb-1">Student details</div>
                      </div>
                    </div>
                    <div class="row custom-row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Name</label>
                          <div *ngIf="callData[0]">
                            {{
                            callData[0]?.FirstName +
                            " " +
                            callData[0].LastName
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">GUID</label>
                          <div *ngIf="callData[0]">{{ callData[0]?.GUID }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">APP Status</label>
                          <div *ngIf="callData[0]">
                            {{ callData[0]?.APPStatus }}
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Submitted Date</label>
                          <div *ngIf="callData[0]">
                            {{ callData[0]?.createdAt | date : "medium" }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row custom-row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">DOB</label>
                          <div *ngIf="callData[0]">{{ callData[0].DOB | date: "dd MMM, yyyy"}}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Enrollment Year</label>
                          <div>{{ callData[0]?.EnrollmentYear }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Program</label>
                          <div>{{ callData[0]?.Program }}</div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Phone Number</label>
                          <div>{{ callData[0]?.PhoneNumber }}</div>
                        </div>
                      </div>
                    </div>

                    <div class="row custom-row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Email</label>
                          <div>{{ callData[0]?.Email }}</div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Address</label>
                          <div>{{ callData[0]?.Address }}</div>
                        </div>
                      </div>
                    </div>
                    <hr class="hr-style mt-1 mb-1" />
                    <div class="row">
                      <div class="col-md-12">
                        <div class="header-md mt-2 mb-1">Document details</div>
                      </div>
                    </div>
                    <div class="row custom-row" *ngFor="
                        let item of documentsList;
                        let i = index;
                        let even = even;
                        let odd = odd
                      ">
                      <div class="col-md-6 py-2" *ngIf="even">
                        <img *ngIf="item.status == true" class="img18 mr-2" src="../../../../assets/img/tick2.svg" />
                        <img *ngIf="item.status == false" class="img18 mr-2" src="../../../../assets/img/minus.svg" />{{
                        documentsList[i].DocumentName }}
                      </div>
                      <div class="col-md-6 py-2" *ngIf="even && i + 1 < documentsList.length">
                        <img *ngIf="documentsList[i + 1].status == true" class="img18 mr-2"
                          src="../../../../assets/img/tick2.svg" />
                        <img *ngIf="documentsList[i + 1].status == false" class="img18 mr-2"
                          src="../../../../assets/img/minus.svg" />{{ documentsList[i + 1].DocumentName }}
                      </div>
                    </div>

                    <hr class="hr-style mt-1 mb-1" />
                    <div class="row custom-row2">
                      <div class="col-md-12">
                        <div class="header-md mt-2 mb-1">Feedback</div>
                      </div>

                      <div class="col-md-12">
                        <div class="form-check mr-3 d-inline">
                          <label class="form-check-label" for="check1">
                            <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check1"
                              name="option1" value="notinterested" (change)="onStatusChange($event.target.value)" />Not
                            Interested
                          </label>
                        </div>
                        <div class="form-check mr-3 d-inline">
                          <label class="form-check-label" for="check3">
                            <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check3"
                              name="option1" value="informed" (change)="onStatusChange($event.target.value)" />Informed
                          </label>
                        </div>
                        <div class="form-check mr-3 d-inline">
                          <label class="form-check-label" for="check4">
                            <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check4"
                              name="option1" value="callfailed" (change)="onStatusChange($event.target.value)" />Wrong
                            Number
                          </label>
                        </div>
                        <div class="form-check mr-3 d-inline">
                          <label class="form-check-label" for="check5">
                            <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check5"
                              name="option1" value="callback" (change)="onStatusChange($event.target.value)" />Call Back
                          </label>
                        </div>
                        <div class="form-check mr-3 d-inline">
                          <label class="form-check-label" for="check6">
                            <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check6"
                              name="option1" value="notanswered" (change)="onStatusChange($event.target.value)" />Not
                            Answered
                          </label>
                        </div>
                        <div class="form-check mr-3 d-inline">
                          <label class="form-check-label" for="check7">
                            <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check7"
                              name="option1" value="deferredintake"
                              (change)="onStatusChange($event.target.value)" />Deferred Intake
                          </label>
                        </div>
                        <div class="form-check mr-3 d-inline">
                          <label class="form-check-label" for="check8">
                            <input type="radio" [(ngModel)]="newStatus" class="form-check-input" id="check8"
                              name="option1" value="uncontactable"
                              (change)="onStatusChange($event.target.value)" />Uncontactable
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="showdeferredintake">
                      <div class="padding-width">
                        <select class="form-control" name="DIyear" id="DIyear" [(ngModel)]="DIyear"
                          (change)="onDIYearChange($event.target.value)">
                          <option [ngValue]="null" [disabled]="true">
                            Select Enrollment Year
                          </option>
                          <option value="{{ item }}" *ngFor="let item of DIyearArray">
                            {{ item }}
                          </option>
                        </select>
                      </div>
                      <div class="padding-width">
                        <select class="form-control" name="DIterms" id="DIterms" [(ngModel)]="DIterms"
                          (change)="onDIItermsChange($event.target.value)">
                          <option [ngValue]="null" [disabled]="true">
                            Select Enrollment Term
                          </option>
                          <option value="G1">G1</option>
                          <option value="G2">G2</option>
                          <option value="G3">G3</option>
                          <option value="G4">G4</option>
                          <option value="G5">G5</option>
                          <option value="G6">G6</option>
                        </select>
                      </div>
                    </div>
                    <span *ngIf="newStatusCheck" class="error-msg" style="color: red; font-size: 15px">
                      Please select any option above!
                    </span>
                    <div class="row custom-row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="commentText" class="header-md pt-3 pb-2" style="font-size: 14px">Comments</label>
                          <textarea class="form-control" id="commentText" rows="3" [(ngModel)]="commentText"></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex justify-content-end">
                      <div class="mt-3">
                        <button (click)="resetCallingScreen()" class="btn btn-light btn-sm mr-2" type="button">
                          Reset
                        </button>
                        <button (click)="updateComments()" class="btn btn-primary btn-sm" type="submit">
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Calling screen from Documents Validation-->
        <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-md">
            <div class="modal-content" style="width: 280px; background: none; border: 0">
              <div class="modal-header" style="
                  border-bottom: 0;
                  display: inline;
                  position: absolute;
                  right: 5px;
                  top: 2px;
                  z-index: 999;
                ">
                <button #closeButton type="button" class="close" data-dismiss="modal" aria-label="Close"
                  style="margin-right: 0">
                  <span aria-hidden="true"><img class="img14" style="width: 10px" src="./assets/img/close.svg" alt="" />
                  </span>
                </button>
              </div>
              <div class="modal-body" style="padding: 0">
                <div class="d-flex">
                  <div class="ndialer" style="width: 275px">
                    <!--.......code for no- incoming call screen.........  -->

                    <div class="dialer-middle-screen" style="text-align: center">
                      <div class="custom-incomingcall-widget">
                        <h4 class="mt-3 text-ellipsis">
                          {{
                          sideViewerData.FirstName +
                          " " +
                          sideViewerData.LastName
                          }}
                        </h4>
                        <div class="my-1 lg-text">
                          {{
                          "+" +
                          sideViewerData.CountryCode +
                          " " +
                          sideViewerData.PhoneNumber
                          }}
                        </div>
                        <div class="custom-user-img my-3">
                          <img class="img80" src="./assets/img/user-w.svg" alt="" />
                        </div>

                        <h5>
                          {{ callStatus }}
                        </h5>


                        <div class="custom-incoming-btns my-3 mt-4">
                          <button type="button" class="btn-round m-r-10" (click)="stopCurrentCall()">
                            <img class="img-38" src="./assets/img/hangup.svg" alt="" />
                          </button>
                        </div>

                        <button type="button" class="btn btn-link btn-sm ml-2 float-left" placement="right"
                          #popover="ngbPopover" [ngbPopover]="comments22" [disabled]="disabled">
                          <img src="./assets/img/comments.svg" style="width: 16px" />
                        </button>

                        <ng-template #comments22>
                          <form #form="ngForm">
                            <div class="d-flex align-items-end" style="width: 180px">
                              <div class="flex-grow-1">
                                <textarea class="form-control ct" type="text" #callingComment id="callingComments"
                                  rows="2" placeholder="Write comments here..."></textarea>
                              </div>
                              <button type="button" class="btn btn-link ml-1 flex-shrink-1" style="padding: 0" (click)="
                                  postComments(currentItem, 'callingComments')
                                ">
                                <img src="./assets/img/send-message.svg" style="width: 16px" />
                              </button>
                            </div>
                          </form>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--Calling popup from Validation  -->

          <!-- add lead -->
          <ng-template #content3 let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Add Lead</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-3" style="max-height: 600px; background: #fff">
              <form (ngSubmit)="createLead()" [formGroup]="uhleadsservice.createLead">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">First Name</label>
                      <input class="form-control" name="firstName" formControlName="firstName" [ngClass]="{
                        'is-invalid': createLeads.firstName.touched && createLeads.firstName.invalid,
                        'is-valid':
                          createLeads.firstName.valid &&
                          createLeads.firstName.touched
                      }" />
                      <div *ngIf="createLeads.firstName.touched && createLeads.firstName.errors"
                        class="invalid-feedback">
                        <div *ngIf="createLeads.firstName.errors.required">First Name is required</div>
                        <div *ngIf="createLeads.firstName.errors.pattern">Please use valid Name</div>
                        <div *ngIf="createLeads.firstName.errors.minlength">Minimum length should be 2 characters .
                        </div>
                        <div *ngIf="createLeads.firstName.errors.maxlength">Maximum length should be 50 characters .
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="form-label">Last Name</label>
                      <input class="form-control" name="lastName" formControlName="lastName" [ngClass]="{
                        'is-invalid': createLeads.lastName.touched && createLeads.lastName.invalid,
                        'is-valid':
                          createLeads.lastName.valid &&
                          createLeads.lastName.touched
                      }" />
                      <div *ngIf="createLeads.lastName.touched && createLeads.lastName.errors" class="invalid-feedback">
                        <div *ngIf="createLeads.lastName.errors.required">LastName is required</div>
                        <div *ngIf="createLeads.lastName.errors.pattern">Please use valid Name</div>
                        <div *ngIf="createLeads.lastName.errors.minlength">Minimum length should be 2 characters .</div>
                        <div *ngIf="createLeads.lastName.errors.maxlength">Maximum length should be 50 characters .
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div class="form-group">
                  <label class="form-label">Phone Number</label>
                  <div class="row">
                    <div class="col-4">
                      <!-- <input class="form-control" name="countryCode" formControlName="countryCode" [ngClass]="{
                        'is-invalid':
                        createLeads.countryCode.touched && createLeads.countryCode.invalid,
                        'is-valid':
                          createLeads.countryCode.valid &&
                          createLeads.countryCode.touched
                      }" list="exampleList">
                      <datalist id="exampleList">
                        <option [value]="country.callingCodes[0]" *ngFor="let country of countries">{{ country.name }} (+{{ country.callingCodes[0] }})</option>
                      </datalist> -->
                      <div class="dropdown">
                        <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1"
                          data-bs-toggle="dropdown" data-toggle="dropdown" aria-expanded="false">
                          +<span class="country-code">{{ selectedCountryCode }}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="    max-height: 200px;
                        overflow: auto;width: max-content;">
                          <input type="search" (input)="onInputC($event)" class="form-control search-all" style="
                          height: auto;
                          padding: 4px 12px;
                          margin: 0 9px;
                        " placeholder="Search " autocomplete="off" />
                          <li *ngFor="let country of countries" style="    padding: 5px;cursor: pointer;">
                            <span href="#" (click)="selectCountry(country)">
                              <img src="{{
                                  country.flag || country.flags.svg || country.flags.png
                                }}" alt="" style="height: 15px; width: 20px" />
                              <span style="padding-left: 8px">{{ country.name }} (+{{ country.callingCodes[0] }})</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div *ngIf="createLeads.countryCode.touched && createLeads.countryCode.errors"
                        class="invalid-feedback">
                        <div *ngIf="createLeads.countryCode.errors.required">Country Code is required</div>
                        <div *ngIf="createLeads.countryCode.errors.pattern">Please use valid country code .</div>
                      </div>
                    </div>
                    <div class="col-8">
                      <input class="form-control" name="phoneNumber" formControlName="phoneNumber" [ngClass]="{
                          'is-invalid':
                          createLeads.phoneNumber.touched && createLeads.phoneNumber.invalid,
                          'is-valid':
                            createLeads.phoneNumber.valid &&
                            createLeads.phoneNumber.touched
                        }" />
                      <div *ngIf="createLeads.phoneNumber.touched && createLeads.phoneNumber.errors"
                        class="invalid-feedback">
                        <div *ngIf="createLeads.phoneNumber.errors.required">Phone Number is required</div>
                        <div *ngIf="createLeads.phoneNumber.errors.pattern">Please use valid phone number .</div>
                        <div *ngIf="createLeads.phoneNumber.errors.minlength">Minimum length should be 7 characters .
                        </div>
                        <div *ngIf="createLeads.phoneNumber.errors.maxlength">Maximum length should be 15 characters .
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Email</label>
                  <input type="text" name="email" class="form-control" formControlName="email" [ngClass]="{
                      'is-invalid': createLeads.email.touched && createLeads.email.invalid,
                      'is-valid':
                        createLeads.email.valid && createLeads.email.touched
                    }" />
                  <div *ngIf="createLeads.email.touched && createLeads.email.errors" class="invalid-feedback">
                    <div *ngIf="createLeads.email.errors.required">Email is required</div>
                    <div *ngIf="createLeads.email.errors.pattern">Please use valid email</div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Program</label>
                  <input type="text" name="program" class="form-control" formControlName="program" [ngClass]="{
                      'is-invalid': createLeads.program.touched && createLeads.program.invalid,
                      'is-valid':
                        createLeads.program.valid && createLeads.program.touched
                    }" />
                  <div *ngIf="createLeads.program.touched && createLeads.program.errors" class="invalid-feedback">
                    <div *ngIf="createLeads.program.errors.required">Program is required</div>
                    <div *ngIf="createLeads.program.errors.pattern">Only characters are allowed .</div>
                    <div *ngIf="createLeads.program.errors.minlength">Minimum length should be 2 characters .</div>
                    <div *ngIf="createLeads.program.errors.maxlength">Maximum length should be 50 characters .</div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-primary btn-sm" [disabled]="!uhleadsservice.createLead.valid">Submit</button>
                </div>
              </form>
            </div>
          </ng-template>

          <ng-template id="pdfContent" class="modal-x" #pdfContent let-modal>
            <div id="progressBar">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <ngx-doc-viewer [url]="docUrl" viewer="google" googleCheckContentLoaded="true" googleCheckInterval="5000"
              googleMaxChecks="15" style="width: 100%; height: 93vh" (loaded)="contentLoaded()">
            </ngx-doc-viewer>
          </ng-template>

          <!-- Edit Lead New -->
          <ng-template #editLead2 let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">
                Update Lead Details
              </h4>
              <button type="button" id="closeButton3" class="close" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="background: #fff">
              <form class="pt-2" [formGroup]="uhleadsservice.editLead" (ngSubmit)="updateLead()">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label>First Name<span style="color: red;">*</span></label>
                    <input class="form-control" name="FirstName" formControlName="FirstName"
                      [(ngModel)]="leadtoEdit.FirstName"
                      [ngClass]="{ 'is-invalid': editLeads.FirstName.touched && editLeads.FirstName.errors }" />
                    <div *ngIf="editLeads.FirstName.touched && editLeads.FirstName.errors" class="invalid-feedback">
                      <div *ngIf="editLeads.FirstName.errors.required">First Name is required</div>
                      <div *ngIf="editLeads.FirstName.errors.pattern">Please use valid Name</div>
                      <div *ngIf="editLeads.FirstName.errors.minlength">Minimum length should be 2 characters .
                      </div>
                      <div *ngIf="editLeads.FirstName.errors.maxlength">Maximum length should be 50 characters .</div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label>Last Name<span style="color: red;">*</span></label>
                    <input class="form-control" name="LastName" formControlName="LastName"
                      [(ngModel)]="leadtoEdit.LastName"
                      [ngClass]="{ 'is-invalid':editLeads.LastName.touched && editLeads.LastName.errors }" />
                    <div *ngIf="editLeads.LastName.touched && editLeads.LastName.errors" class="invalid-feedback">
                      <div *ngIf="editLeads.LastName.errors.required">Last Name is required</div>
                      <div *ngIf="editLeads.LastName.errors.pattern">Please use valid Name</div>
                      <div *ngIf="editLeads.LastName.errors.minlength">Minimum length should be 2 characters .
                      </div>
                      <div *ngIf="editLeads.LastName.errors.maxlength">Maximum length should be 50 characters .</div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label>Date of Birth</label>
                    <input type="date" name="DOB" [value]="leadtoEdit.DOB | date : 'yyyy-MM-dd'" class="form-control"
                      formControlName="DOB" [(ngModel)]="leadtoEdit.DOB" />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label>Email<span style="color: red;">*</span></label>
                    <input type="text" name="Email" class="form-control" formControlName="Email"
                      [(ngModel)]="leadtoEdit.Email"
                      [ngClass]="{ 'is-invalid': editLeads.Email.touched && editLeads.Email.errors }" />
                    <div *ngIf="editLeads.Email.touched && editLeads.Email.errors" class="invalid-feedback">
                      <div *ngIf="editLeads.Email.errors.required">Email is required</div>
                      <div *ngIf="editLeads.Email.errors.pattern">Please use valid Email</div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label>Phone Number<span style="color: red;">*</span></label>
                    <div class="form-row">
                      <div class="col-4">
                        <!-- <input class="form-control" name="CountryCode" formControlName="CountryCode"
                          [(ngModel)]="leadtoEdit.CountryCode"
                          [ngClass]="{ 'is-invalid': editLeads.CountryCode.touched && editLeads.CountryCode.errors }" /> -->
                        <div class="dropdown">
                          <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" data-toggle="dropdown" aria-expanded="false">
                            +<span class="country-code">{{ selectedCountryCode }}</span>
                          </button>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="    max-height: 200px;
                            overflow: auto;width: max-content;">
                            <input type="search" (input)="onInputC($event)" class="form-control search-all" style="
                              height: auto;
                              padding: 4px 12px;
                              margin: 0 9px;
                            " placeholder="Search " autocomplete="off" />
                            <li *ngFor="let country of countries" style="    padding: 5px;cursor: pointer;">
                              <span href="#" (click)="selectCountryEdit(country)">
                                <img src="{{
                                      country.flag || country.flags.svg || country.flags.png
                                    }}" alt="" style="height: 15px; width: 20px" />
                                <span style="padding-left: 8px">{{ country.name }} (+{{ country.callingCodes[0]
                                  }})</span>
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div *ngIf="editLeads.CountryCode.touched && editLeads.CountryCode.errors"
                          class="invalid-feedback">
                          <div *ngIf="editLeads.CountryCode.errors.required">Country Code is required</div>
                          <div *ngIf="editLeads.CountryCode.errors.pattern">Only Numbers are allowed</div>
                        </div>
                      </div>
                      <div class="col-8">
                        <input class="form-control" name="PhoneNumber" formControlName="PhoneNumber"
                          [(ngModel)]="leadtoEdit.PhoneNumber"
                          [ngClass]="{ 'is-invalid': editLeads.PhoneNumber.touched && editLeads.PhoneNumber.errors }" />

                        <div *ngIf="editLeads.PhoneNumber.touched && editLeads.PhoneNumber.errors"
                          class="invalid-feedback">
                          <div *ngIf="editLeads.PhoneNumber.errors.required">Phone Number is required</div>
                          <div *ngIf="editLeads.PhoneNumber.errors.pattern">Only numbers are allowed</div>
                          <div *ngIf="editLeads.PhoneNumber.errors.minlength">Minimum length should be 7 characters .
                          </div>
                          <div *ngIf="editLeads.PhoneNumber.errors.maxlength">Maximum length should be 15 characters .
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">Enrollment Year<span style="color: red;">*</span></label>
                    <input type="text" name="EnrollmentYear" class="form-control" formControlName="EnrollmentYear"
                      [(ngModel)]="leadtoEdit.EnrollmentYear"
                      [ngClass]="{ 'is-invalid': editLeads.EnrollmentYear.touched && editLeads.EnrollmentYear.errors }" />

                    <div *ngIf="editLeads.EnrollmentYear.touched && editLeads.EnrollmentYear.errors"
                      class="invalid-feedback">
                      <div *ngIf="editLeads.EnrollmentYear.errors.required">Enrollment Year is required</div>
                      <div *ngIf="editLeads.EnrollmentYear.errors.pattern">Only numbers are allowed</div>
                    </div>
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Enrollment Term<span style="color: red;">*</span></label>
                    <input type="text" name="EnrollmentTerm" class="form-control" formControlName="EnrollmentTerm"
                      [(ngModel)]="leadtoEdit.EnrollmentTerm"
                      [ngClass]="{ 'is-invalid': editLeads.EnrollmentTerm.touched && editLeads.EnrollmentTerm.errors }" />

                    <div *ngIf="editLeads.EnrollmentTerm.touched && editLeads.EnrollmentTerm.errors"
                      class="invalid-feedback">
                      <div *ngIf="editLeads.EnrollmentTerm.errors.required">Enrollment Term is required</div>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">Address </label>
                    <input type="text" name="Address" class="form-control" formControlName="Address"
                      [(ngModel)]="leadtoEdit.Address" />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">City </label>
                    <input type="text" name="City" class="form-control" formControlName="City"
                      [(ngModel)]="leadtoEdit.City" />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">State </label>
                    <input type="text" name="State" class="form-control" formControlName="State"
                      [(ngModel)]="leadtoEdit.State" />
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">Zip </label>
                    <input type="text" name="Zip" class="form-control" formControlName="Zip"
                      [(ngModel)]="leadtoEdit.Zip" />
                  </div>

                  <div class="form-group col-md-4">
                    <label for="">Country </label>
                    <!-- <input type="text" name="Country" class="form-control" formControlName="Country"
                      [(ngModel)]="leadtoEdit.Country" /> -->
                    <div class="dropdown">
                      <button class="form-control dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" data-toggle="dropdown" aria-expanded="false">
                        <span class="country-code">{{ selectedCountryName }}</span>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style="    max-height: 200px;
                        overflow: auto;width: max-content;">
                        <input type="search" (input)="onInputC($event)" class="form-control search-all" style="
                          height: auto;
                          padding: 4px 12px;
                          margin: 0 9px;
                        " placeholder="Search " autocomplete="off" />
                        <li *ngFor="let country of countries" style="    padding: 5px;cursor: pointer;">
                          <span href="#" (click)="selectCountryEditName(country)">
                            <img src="{{
                                  country.flag || country.flags.svg || country.flags.png
                                }}" alt="" style="height: 15px; width: 20px" />
                            <span style="padding-left: 8px">{{ country.name }}</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="">Comments </label>
                    <input type="text" name="LeadComment" class="form-control" formControlName="LeadComment"
                      [(ngModel)]="leadtoEdit.LeadComment" />
                  </div>
                </div>

                <div class="form-row">
                  <!-- Radio button  -->

                  <div class="form-group col-md-12">
                    <label for="">Feedback</label>
                    <div class="mt-2">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio1" value="pending"
                          formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                          (change)="onStatusChange($event.target.value)" />
                        <label class="form-check-label" for="inlineRadio1">Pending</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio2"
                          value="notinterested" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                          (change)="onStatusChange($event.target.value)" />
                        <label class="form-check-label" for="inlineRadio2">Not Interested</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadi03"
                          value="informed" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                          (change)="onStatusChange($event.target.value)" />
                        <label class="form-check-label" for="inlineRadio3">Informed</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio4"
                          value="callfailed" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                          (change)="onStatusChange($event.target.value)" />
                        <label class="form-check-label" for="inlineRadio4">Wrong Number</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio5"
                          value="callback" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                          (change)="onStatusChange($event.target.value)" />
                        <label class="form-check-label" for="inlineRadio5">Call Back</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio6"
                          value="notanswered" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                          (change)="onStatusChange($event.target.value)" />
                        <label class="form-check-label" for="inlineRadio6">Not Answered</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio7"
                          value="deferredintake" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                          (change)="onStatusChange($event.target.value)" />
                        <label class="form-check-label" for="inlineRadio7">Deferred Intake</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="CallStatus" id="inlineRadio8"
                          value="uncontactable" formControlName="CallStatus" [(ngModel)]="leadtoEdit.CallStatus"
                          (change)="onStatusChange($event.target.value)" />
                        <label class="form-check-label" for="inlineRadio8">Uncontactable</label>
                      </div>
                    </div>
                    <div class="row" *ngIf="showdeferredintake">
                      <div class="padding-width">
                        <select class="form-control" name="DIyear" id="DIyear" [value]="DIyear"
                          (change)="onDIYearChange($event.target.value)">
                          <option [ngValue]="null" [disabled]="true">
                            Select Enrollment Year
                          </option>
                          <option value="{{ item }}" *ngFor="let item of DIyearArray">
                            {{ item }}
                          </option>
                        </select>
                      </div>
                      <div class="padding-width">
                        <select class="form-control" name="DIterms" id="DIterms" [value]="DIterms"
                          (change)="onDIItermsChange($event.target.value)">
                          <option [ngValue]="null" [disabled]="true">
                            Select Enrollment Term
                          </option>
                          <option value="G1">G1</option>
                          <option value="G2">G2</option>
                          <option value="G3">G3</option>
                          <option value="G4">G4</option>
                          <option value="G5">G5</option>
                          <option value="G6">G6</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <div class="mt-3">
                    <button class="btn btn-primary btn-sm" type="submit" [disabled]="!uhleadsservice.editLead.valid">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>

          <ng-template id="totalCallCount" class="modal-x" #totalCallCount let-modal>
            <div class="modal-header nm">
              <h4 class="modal-title" id="modal-basic-title">Call</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="px-2">
                <div class="py-2 header-md" style="color: #163252">
                  You have reached the calling limit.<br />Are you sure you want
                  to continue?
                </div>
                <button type="button" class="btn btn-primary btn-sm mr-4" (click)="thresHold()">
                  Confirm
                </button>
                <button type="button" class="btn btn-primary btn-sm" (click)="modal.dismiss('Cross click')">
                  Cancel
                </button>
              </div>
            </div>
          </ng-template>
        </div>

        <!-- <simple-notifications [options]="options"></simple-notifications> -->
      </div>
    </div>
  </div>
</div>