import { SmsmodulesComponent } from "./smsmodules/smsmodules.component";
import { CompanymodulesComponent } from "./companymodules/companymodules.component";
import { ProfileComponent } from "./profile/profile.component";
import { TemplatesComponent } from "./templates/templates.component";
import { EmailsettingsComponent } from "./emailsettings/emailsettings.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../core/guards/auth.guard";
import { CreateAgentComponent } from "./create-agent/create-agent.component";
import { GroupManagementComponent } from "./group-management/group-management.component";
import { RoleManagementComponent } from "./role-management/role-management.component";
import { CustomTableComponent } from "./custom-table/custom-table.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { CompanyManagementComponent } from "./company-management/company-management.component";
import { CalllogsComponent } from "./calllogs/calllogs.component";
import { ProjectManagementComponent } from "./project-management/project-management.component";
import { HellosignComponent } from "./hellosign/hellosign.component";
import { UhleadsComponent } from "./uhleads/uhleads.component";
import { ReportsComponent } from "./reports/reports.component";
import { ReferralComponent } from "./referral/referral.component";
import { ReferreeComponent } from "./referree/referree.component";
import { DashboardReportsComponent } from "./dashboard-reports/dashboard-reports.component";
import { MonitoringComponent } from "./monitoring/monitoring.component";
import { GoogleAnalyticsComponent } from "./google-analytics/google-analytics/google-analytics.component";
import { AppAnalyticsComponent } from "./dashboard-reports/app-analytics/app-analytics.component";
import { CallAnalyticsComponent } from "./dashboard-reports/call-analytics/call-analytics.component";
import { EnquiryComponent } from "./enquiry/enquiry.component";
import { UploadComponent } from "./upload/upload.component";
import { UhubDashboardComponent } from "./uhub-dashboard/uhub-dashboard.component";
import { CalendarComponent } from "./calendar/calendar.component";
import { AppointmentsComponent } from "./calendar/appointment/appointment.component";
import { AutoCallSMSComponent } from "./calendar/auto-call-sms/auto-call-sms.component";
import { RecruiterComponent } from "./recruiter/recruiter.component";
import { CuNextComponent } from "./cu-next/cu-next.component";
import { JudsonuLeadsComponent } from "./judsonu-leads/judsonu-leads.component";
import { SfcLeadsComponent } from "./sfc-leads/sfc-leads.component";
import { DocumentationComponent } from "./documentation/documentation.component";
import { UwlaComponent } from "./uwla/uwla.component"
import { SocialmedialeadsComponent } from "./uhub-dashboard/socialmedialeads/socialmedialeads.component";
import { RecruiterleadsComponent } from "./uhub-dashboard/recruiterleads/recruiterleads.component";
const routes: Routes = [
  {
    path: "mail",
    loadChildren: () => import("./mail/mail.module").then((d) => d.MailModule),
    canActivate: [AuthGuard],
  },
  {
    path: "sms",
    loadChildren: () => import("./sms/sms.module").then((m) => m.SmsModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "tickets",
    loadChildren: () =>
      import("./tickets/tickets.module").then((m) => m.TicketsModule),
    canActivate: [AuthGuard],
  },
  { path: "agents", component: CreateAgentComponent, canActivate: [AuthGuard] },
  {
    path: "role",
    component: RoleManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "group",
    component: GroupManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings/email",
    component: EmailsettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "settings/email/:id",
    component: EmailsettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "templates",
    component: TemplatesComponent,
    canActivate: [AuthGuard],
  },
  { path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: "tickets/customTable/:pageName",
    component: CustomTableComponent,
    canActivate: [AuthGuard],
  },
  { path: "contacts", component: ContactsComponent, canActivate: [AuthGuard] },
  { path: "referral", component: ReferralComponent, canActivate: [AuthGuard] },
  { path: "referree", component: ReferreeComponent, canActivate: [AuthGuard] },
  { path: "analytics", component: GoogleAnalyticsComponent, canActivate: [AuthGuard] },


  {
    path: "company",
    component: CompanyManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "role/:id/:name/:projectId",
    component: RoleManagementComponent,
    canActivate: [AuthGuard],
  },
  { path: "calllogs", component: CalllogsComponent, canActivate: [AuthGuard] },
  {
    path: "callModules",
    component: CompanymodulesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "SMSModules",
    component: SmsmodulesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "projects/:companyId/:name",
    component: ProjectManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "hellosign",
    component: HellosignComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "uhleads",
    component: UhleadsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "judsonuleads",
    component: JudsonuLeadsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "sfcleads",
    component: SfcLeadsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "uwlaleads",
    component: UwlaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports2",
    component: ReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports",
    component: DashboardReportsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "appreports",
    component: AppAnalyticsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "callreports",
    component: CallAnalyticsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "cunext",
    component: CuNextComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "enquiries",
    component: EnquiryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "monitoring",
    component: MonitoringComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "calendar",
    component: CalendarComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "appointments",
    component: AppointmentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "auto-call-sms",
    component: AutoCallSMSComponent,
    canActivate: [AuthGuard],
  },
  
  {
    path: 'transfers',
    loadChildren: () =>
      import("./trasnfers/trasnfers.module").then((m) => m.TrasnfersModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'upload/:id',
    component: UploadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'upload/:id/:menu',
    component: UploadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'uhub',
    component: UhubDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'socialmedia/leads',
    component: SocialmedialeadsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recruiter/leads',
    component: RecruiterleadsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'recruiter',
    component: RecruiterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documentation',
    component: DocumentationComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
